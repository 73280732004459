import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const theme = createTheme({
    palette: {
        background: {
            default: '#f0f0f0'
        },
        primary: {
            main: '#385480',
        },
        success: {
            main: '#458b74'
        },
        text: {
            secondary: '#808080',
        },
        search: {
            main: '#5874a0',
            primary: '#5874a0',
            secondary: '#7894c0',
            light: '#7894c0',
            dark: '#385480'
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#ffffff',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#385480',
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#e0e0e0',
                        },
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f9f9f9', // Set the background color to white
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    backgroundColor: '#f9f9f9',
                    '&.Mui-disabled': {
                        backgroundColor: '#f0f0f0'
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f9f9f9',
                }
            }
        },
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    success: <CheckCircleOutlineIcon fontSize="inherit"/>,
                    warning: <WarningAmberIcon fontSize="inherit"/>,
                    error: <ErrorOutlineIcon fontSize="inherit"/>,
                    info: <InfoOutlinedIcon fontSize="inherit"/>,
                    realtimeAlert: <PriorityHighIcon fontSize="inherit"/>,
                },
            },
            variants: [
                {
                    props: {severity: 'realtimeAlert'},
                    style: {
                        backgroundColor: '#e63636',
                        color: '#ffeded',
                        '& .MuiAlert-icon': {
                            color: '#ffef00',
                        },
                    },
                },
            ],
        },
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
