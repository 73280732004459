import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {Alert, AlertTitle, Collapse, IconButton, InputAdornment} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ASLogo from "../assets/logo/as_logo_small.png";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import PasswordIcon from '@mui/icons-material/Password';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {userFields} from "../util/modelFields";
import Button from "@mui/material/Button";
import {useGetResetPasswordConfirm, usePostResetPasswordConfirm} from "../routes/api";
import Link from "@mui/material/Link";
import {LoadingComponent} from "../util/loading";

export default function ResetPassword() {
    const {uid, pwtoken} = useParams();
    const field = userFields.fields.password;
    const pwRegexp = new RegExp(field.regexPattern);

    const [validated, setValidated] = useState(null);
    const [alert, setAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordForm, setPasswordForm] = useState({});
    const [fieldChecks, setFieldChecks] = useState({});
    const [message, setMessage] = useState('');
    const [returnValue, setReturnValue] = useState('error');
    const [secondsLeft, setSecondsLeft] = useState(-1);

    const {
        data: getData,
        statusCode: getStatusCode,
        handleCall: validateCall
    } = useGetResetPasswordConfirm(uid, pwtoken);
    const {
        data: postData,
        statusCode: postStatusCode,
        handleCall: postCall
    } = usePostResetPasswordConfirm(uid, pwtoken);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await postCall({body: passwordForm});
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handlePasswordChange = (e, regexp) => {
        if (!e.target.value) {
            const formData = {...passwordForm};
            delete formData[e.target.name];
            setPasswordForm(formData);
        } else {
            setPasswordForm({
                ...passwordForm, [e.target.name]: e.target.value
            })
            if (regexp) {
                setFieldChecks({
                    ...fieldChecks, [e.target.name]: !regexp.test(e.target.value)
                });
            }
        }
    }

    const validateLink = async () => {
        try {
            await validateCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        validateLink();
    }, []);

    useEffect(() => {
        if (validated === false) {
            navigate('forgot-password');
        }
    }, [validated]);

    useEffect(() => {
        if (getStatusCode >= 200 && getStatusCode < 300) {
            setValidated(true);
        } else if (getStatusCode >= 400) {
            setValidated(false);
        }
    }, [getStatusCode, getData]);

    useEffect(() => {
        if (postStatusCode >= 200 && postStatusCode < 300) {
            setReturnValue('success');
            setSecondsLeft(5);
        } else if (postStatusCode >= 400) {
            setAlert(true);
            setReturnValue('error');
            setMessage(String(postData));
        }

    }, [postStatusCode, postData]);

    useEffect(() => {
        let intervalId;

        if (secondsLeft > 0) {
            intervalId = setInterval(() => {
                setSecondsLeft((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (secondsLeft === 0) {
            navigate('/login');
        }

        return () => clearInterval(intervalId);
    }, [secondsLeft]);

    if (validated === null) {
        return <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <img src={ASLogo} alt='Aglaia Sense' style={{width: '60%'}}/>
                <Typography component="h1" variant="h5" color="#323232" sx={{mb: 3, mt: 1, fontWeight: 'bold'}}>
                    Device Manager
                </Typography>
                <Avatar sx={{m: 1, bgcolor: 'primary.light'}}>
                    <PasswordIcon/>
                </Avatar>
                <Typography component="h2" variant="h6" color="textPrimary">
                    Reset Password
                </Typography>
                <LoadingComponent/>
            </Box>
        </Container>

    }

    if (returnValue === 'success') {
        return <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <img src={ASLogo} alt='Aglaia Sense' style={{width: '60%'}}/>
                <Typography component="h1" variant="h5" color="#323232" sx={{mb: 3, mt: 1, fontWeight: 'bold'}}>
                    Device Manager
                </Typography>
                <Avatar sx={{m: 1, bgcolor: 'primary.light'}}>
                    <PasswordIcon/>
                </Avatar>
                <Typography>
                    Password reset successful. Redirecting to log in page...
                </Typography>
                <Typography>
                    If not redirected in 5 seconds,
                    use <Link to='/login' sx={{color: 'inherit'}}>
                    this link
                </Link> to redirect.
                </Typography>
            </Box>
        </Container>
    }
    if (validated === true) {
        return <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Collapse in={alert}>
                <Alert severity={returnValue === 'success' ? 'success' : 'error'}
                       action={<IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                               setAlert(false);
                           }}
                       >
                           <CloseIcon fontSize="inherit"/>
                       </IconButton>}
                       sx={{mb: 2}}
                >
                    <AlertTitle>{returnValue ? 'Thank you' : 'Error'}</AlertTitle>
                    {message}
                </Alert>
            </Collapse>

            <Box
                sx={{
                    marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <img src={ASLogo} alt='Aglaia Sense' style={{width: '60%'}}/>
                <Typography component="h1" variant="h5" color="#323232" sx={{mb: 3, mt: 1, fontWeight: 'bold'}}>
                    Device Manager
                </Typography>
                <Avatar sx={{m: 1, bgcolor: 'primary.light'}}>
                    <PasswordIcon/>
                </Avatar>
                <Typography component="h2" variant="h6" color="textPrimary">
                    Reset Password
                </Typography>
                <Box component="form"
                     onSubmit={handleSubmit}
                     noValidate
                     sx={{
                         mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '400px'
                     }}
                >
                    <TextField
                        fullWidth
                        margin='normal'
                        required={true}
                        name='new_pw'
                        label='Password'
                        type={showPassword ? 'text' : 'password'}
                        id='new_pw'
                        error={passwordForm?.new_pw && fieldChecks.new_pw}
                        onChange={(e) => handlePasswordChange(e, pwRegexp)}
                        defaultValue=''
                        placeholder=''
                        helperText={field.helperText}
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </IconButton>
                            </InputAdornment>),
                        }}
                    />
                    <TextField
                        fullWidth
                        margin='normal'
                        required
                        name='confirm_pw'
                        label='Confirm Password'
                        type={showPassword ? 'text' : 'password'}
                        id='confirm_pw'
                        error={passwordForm.confirm_pw && (fieldChecks.confirm_pw || passwordForm?.confirm_pw !== passwordForm?.new_pw)}
                        rows={4}
                        onChange={(e) => handlePasswordChange(e, /.*/)}
                        helperText={(passwordForm.confirm_pw !== passwordForm.password) && "passwords don't match"}
                        placeholder='please confirm password'
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </IconButton>
                            </InputAdornment>),
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!passwordForm.new_pw || !passwordForm.confirm_pw || Object.values(fieldChecks).filter((item) => (item)).length !== 0 || passwordForm.new_pw !== passwordForm.confirm_pw}
                        sx={{mt: 3, mb: 2}}
                    >
                        Update Password
                    </Button>
                </Box>
            </Box>
        </Container>
    }
}