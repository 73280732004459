import {createContext, useEffect, useState} from "react";
import Cookies from "js-cookie";
import {BASE_URL} from "../routes/api";

export const EnumContext = createContext({
    enums: {},
    updateEnums: () => {
    },
});

export default function EnumProvider({children}) {
    const token = Cookies.get('token');
    const [enums, setEnums] = useState(() => {
        const storedData = localStorage.getItem('enums');
        return storedData ? JSON.parse(storedData) : {};
    });


    useEffect(() => {
        const fetchEnums = async () => {
            const response = await fetch(
                `${BASE_URL}/getenums/`,
                {
                    headers: {
                        'Content-Type': 'application/json', 'Authorization': `Token ${token}`,
                    }
                }
            );
            if (response.ok) {
                const jsonData = await response.json();
                setEnums(jsonData);
                localStorage.setItem('enums', JSON.stringify(jsonData));

            }
        }
        if (token && Object.keys(enums).length <= 0) {
            fetchEnums();
        }
    }, [enums]);

    const updateEnums = (value) => {
        setEnums(value);
    };

    const context = {
        enums: enums,
        updateEnums: updateEnums
    }

    return <EnumContext.Provider value={context}>{children}</EnumContext.Provider>;
}
