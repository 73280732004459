import React, {useContext, useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Alert, AlertTitle, Collapse, IconButton, InputAdornment} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {BASE_URL, useLogIn} from "../routes/api";
import {LoadingComponent} from "../util/loading";
import Cookies from 'js-cookie';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {EnumContext} from "../components/enumContext";
import ASLogo from "../assets/logo/as_logo_small.png";


export default function SignIn() {
    localStorage.clear();
    const token = Cookies.get('token');
    const [alert, setAlert] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({"username": '', "password": ''});
    const [loading, setLoading] = useState(false);
    const {data, statusCode, handleCall} = useLogIn();
    const [touched, setTouched] = useState(false);
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const {from} = location.state || {from: {pathname: "/"}};

    const {updateEnums} = useContext(EnumContext);

    useEffect(() => {
        if (token) {
            navigate('/');
        }
        setFormData({"username": '', "password": ''})
    }, []);

    const handleBlur = () => {
        setTouched(true);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        Cookies.set('username', formData.username);
        setLoading(true);
        await handleCall({body: formData});
        setLoading(false);
        setFormData({...formData, "password": ''});
        setShowPassword(false);
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    }

    useEffect(() => {
        const fetchEnums = async () => {
            const response = await fetch(
                `${BASE_URL}/getenums/`,
                {
                    headers: {
                        'Content-Type': 'application/json', 'Authorization': `Token ${data.token}`,
                    }
                }
            );
            if (response.ok) {
                updateEnums(await response.json());
            }
        }
        if (data && data.token && data.permissions) {
            Cookies.set('token', data.token, {expires: 1});
            Cookies.set('permissions', JSON.stringify(data.permissions), {expires: 1});
            Cookies.set('webAdmin', data.webadmin ? 'T' : 'F', {expires: 1});
            fetchEnums();
            setMessage('');
            navigate(from);
        } else if (statusCode === 203) {
            navigate('/validate', {state: {from: from}});
        } else if (statusCode >= 400) {
            setAlert(true);
            if (statusCode === 400) {
                setMessage('Username or password incorrect. Please try again.');
            } else {
                setMessage(typeof data === 'string' ? data : JSON.stringify(data));
            }
        }
    }, [data, statusCode]);


    if (loading) return <LoadingComponent/>

    return <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Collapse in={alert}>
            <Alert severity="error"
                   action={
                       <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                               setAlert(false);
                           }}
                       >
                           <CloseIcon fontSize="inherit"/>
                       </IconButton>
                   }
                   sx={{mb: 2}}
            >
                <AlertTitle>Error</AlertTitle>
                {message}
            </Alert>
        </Collapse>

        <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <img src={ASLogo} alt='Aglaia Sense' style={{width: '60%'}}/>
            <Typography component="h1" variant="h5" color="#323232" sx={{mb: 3, mt: 1, fontWeight: 'bold'}}>
                Device Manager
            </Typography>
            <Avatar sx={{m: 1, bgcolor: 'primary.light'}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h2" variant="h6" color="textPrimary">
                Log in
            </Typography>
            <Box component="form"
                 onSubmit={handleSubmit}
                 noValidate
                 sx={{
                     mt: 1,
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     minWidth: '400px'
                 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    error={touched && !formData.username}
                    onBlur={handleBlur}
                    value={formData.username}
                    onChange={handleChange}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched && !formData.password}
                    value={formData.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{shrink: true}}
                />
                <Typography
                    variant="caption"
                    onClick={handleForgotPassword}
                    sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    Forgot Password?
                </Typography>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!formData.username || !formData.password}
                    sx={{mt: 3, mb: 2}}
                >
                    Log In
                </Button>
            </Box>
            <Typography variant="caption" display="block" style={{marginTop: '20px'}}>
                Need an account? Please
                contact your organization admin, or the website admin at <span
                style={{fontWeight: 'bold'}}>support@aglaiasense.com</span>.
            </Typography>
        </Box>
    </Container>
}