import Cookies from "js-cookie";
import {routes, usePostData} from "../routes/api";
import {getCreateFields, getRequiredFields, isDict} from "../util/util";
import {accountFields, userFields} from "../util/modelFields";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {LoadingComponent} from "../util/loading";
import {Grid, IconButton, InputAdornment, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import InputField from "../components/inputField";
import RelationView from "../components/relationView";
import TextField from "@mui/material/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorDisplay from "../components/errorDisplay";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";

export default function AccountCreate() {
    const token = Cookies.get('token');
    const permissions = Cookies.get('permissions') ?
        JSON.parse(Cookies.get('permissions')).account :
        {create: false, edit: false, delete: false, view: false};
    const webAdmin = Cookies.get('webAdmin') === 'T';
    const accountRoute = routes.account;
    const accountRequiredFields = getRequiredFields(accountFields.fields);
    const userRequiredFields = getRequiredFields(userFields.fields);
    const accountCreateFields = getCreateFields(accountFields.fields);
    const userCreateFields = getCreateFields(userFields.fields);

    const passwordRegexp = new RegExp(userFields.fields.password.regexPattern);

    const [accountFormdata, setAccountFormdata] = useState(Object.values(accountFields.fields).filter(item => item.defaultValue).reduce((acc, item) => ({
        ...acc,
        [item.alias]: item.defaultValue
    }), {}));
    const [userFormdata, setUserFormdata] = useState({});
    const [accountProperties, setAccountProperties] = useState({});
    const [error, setError] = useState(false);
    const [propertyError, setPropertyError] = useState(false);
    const [touched, setTouched] = useState({});
    const [fieldChecks, setFieldChecks] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const {
        data: accountPostResp,
        statusCode: accountPostStatus,
        handleCall: postAccount
    } = usePostData(token, accountRoute);

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from ? location.state.from : {pathname: "/account"};

    const handleAccountChange = (e, regexp) => {
        if (!e.target.value) {
            const formData = {...accountFormdata};
            delete formData[e.target.name];
            setAccountFormdata(formData);
        } else {
            setAccountFormdata({
                ...accountFormdata,
                [e.target.name]: e.target.value
            })
            if (regexp) {
                setFieldChecks({
                    ...fieldChecks,
                    [e.target.name]: !regexp.test(e.target.value)
                });
            }
        }

        setErrorFields(errorFields.filter(item => item !== e.target.name));
    }

    const handleAccountBoolChange = (e) => {
        setAccountFormdata({
            ...accountFormdata,
            [e.target.name]: e.target.checked
        })
    }

    const handleRelationChange = (newValue, key, multiple, limit) => {
        if (multiple) {
            if (!limit || !newValue || newValue.length <= limit) {
                setAccountFormdata({
                    ...accountFormdata,
                    [key]: newValue.map((item) => item[0])
                });
            }
        } else if (!newValue) {
            const tempForm = {...accountFormdata};
            delete tempForm[key];
            setAccountFormdata(tempForm);
        } else {
            setAccountFormdata({
                ...accountFormdata,
                [key]: newValue[0]
            });
        }
    }

    const handleNew = (value, key, multiple, limit) => {
        if (!multiple) {
            setAccountFormdata({
                ...accountFormdata,
                [key]: value
            });
        } else {
            if (accountFormdata[key]) {
                if (accountFormdata[key].length < limit) {
                    setAccountFormdata({
                        ...accountFormdata,
                        [key]: [...accountFormdata[key], value]
                    });
                }
            } else {
                setAccountFormdata({
                    ...accountFormdata,
                    [key]: [value]
                });
            }
        }
    }


    const handleUserChange = (e, regexp) => {
        if (!e.target.value) {
            const formData = {...userFormdata};
            delete formData[e.target.name];
            setUserFormdata(formData);
        } else {
            setUserFormdata({
                ...userFormdata,
                [e.target.name]: e.target.value
            })
            if (regexp) {
                setFieldChecks({
                    ...fieldChecks,
                    [e.target.name]: !regexp.test(e.target.value)
                });
            }
        }

        setErrorFields(errorFields.filter(item => item !== e.target.name));
    }

    const handleDateChange = (key, value) => {
        setAccountFormdata({
            ...accountFormdata,
            [key]: value.format('YYYY-MM-DD')
        })
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };
    const handleSubmitAccount = async () => {
        setLoading(true);
        await postAccount({
            body: {
                ...accountFormdata,
                a_user: userFormdata,
                // new_property: Object.values(accountProperties)
            }
        });
        setLoading(false);
    }

    useLayoutEffect(() => {
        if (!(permissions.create || webAdmin)) {
            navigate(from);
        }
    }, []);

    useEffect(() => {
        if (accountPostStatus >= 200 && accountPostStatus < 300) {
            setAlert(false);
            navigate(from);
        } else if (accountPostStatus > 300) {
            setAlert(true);
            setUserFormdata({...userFormdata, password: null, password_confirm: null});
            const errors = isDict(accountPostResp) ? Object.keys(accountPostResp) : [];
            const userErrors = errors.includes('a_user') && isDict(accountPostResp.a_user) ? Object.keys(accountPostResp.a_user) : [];
            setErrorFields([...errors, ...userErrors].filter(item => item !== 'a_user'));
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [accountPostStatus, accountPostResp]);


    useEffect(() => {
        let currentError = accountRequiredFields.reduce((acc, item, _) => (acc || (!accountFormdata[item])), false);
        currentError = userRequiredFields.reduce((acc, item, _) => (acc || (!userFormdata[item])), currentError);
        currentError = Object.values(fieldChecks).reduce((acc, item, _) => (acc || item), currentError);
        setError(currentError || propertyError || errorFields.length !== 0);
    }, [userRequiredFields, accountRequiredFields, propertyError, accountFormdata, fieldChecks, userFormdata])

    const onBlur = (e) => {
        setTouched({...touched, [e.target.name]: true});
    }

    if (loading) return <LoadingComponent/>

    return (
        <Grid container spacing={2} alignItems="baseline" justifyContent="flex-start"
              sx={{margin: 'auto', mt: 5, width: '80%'}}>
            <Grid item xs={12}>
                <ErrorDisplay
                    alert={alert}
                    setAlert={setAlert}
                    response={accountPostResp}
                    fields={accountFields}
                    statusCode={accountPostStatus}
                    account
                />
            </Grid>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='account' display='Account' create/>
            </Grid>
            <Grid item xs={12} sx={{mt: 5, mb: 5}}>
                <Typography variant='h4'>Account Detail</Typography>
            </Grid>
            {Object.values(accountCreateFields).map((field) => (
                <Grid item xs={12} md={6} xl={3} key={field.alias}
                      sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                    <InputField
                        field={field}
                        error={errorFields.includes(field.alias)}
                        onChange={handleAccountChange}
                        onDateChange={handleDateChange}
                        onBoolChange={handleAccountBoolChange}
                        onBlur={onBlur}
                        value={accountFormdata[field.alias]}
                        regexCheck={fieldChecks}
                    />
                </Grid>
            ))}
            {Object.values(userCreateFields).map((field) => (
                <Grid item xs={12} md={6} xl={3} key={field.alias}
                      sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                    <InputField field={field}
                                error={errorFields.includes(field.alias)}
                                onChange={handleUserChange}
                                onDateChange={handleDateChange}
                                onBlur={onBlur}
                                value={userFormdata[field.alias]}
                                regexCheck={fieldChecks}
                    />
                </Grid>
            ))}
            <Grid item xs={12} md={6} xl={3}
                  sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                <TextField
                    fullWidth
                    margin='normal'
                    required
                    name='password_confirm'
                    label='Confirm Password'
                    type={showPassword ? 'text' : 'password'}
                    id='password_confirm'
                    error={!userFormdata['password_confirm'] ||
                        !passwordRegexp.test(userFormdata['password_confirm']) ||
                        userFormdata['password_confirm'] !== userFormdata['password'] ||
                        errorFields.includes('password_confirm')
                    }
                    rows={4}
                    onChange={(e) => handleUserChange(e, /.*/)}
                    helperText={(userFormdata['password_confirm'] !== userFormdata['password']) && "passwords don't match"}
                    placeholder='please confirm password'
                    onBlur={onBlur}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{maxWidth: 350}}
                />
            </Grid>

            <Grid item xs={12}>
                <RelationView
                    alias='account'
                    fields={accountFields.relations}
                    relationName={['organization']}
                    handleChange={handleRelationChange}
                    handleNew={handleNew}
                    currentValue={accountFormdata}
                />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <Properties*/}
            {/*        display="Account"*/}
            {/*        setData={setAccountProperties}*/}
            {/*        data={accountProperties}*/}
            {/*        setError={setPropertyError}*/}
            {/*        prefix="ap"*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid item xs={12} sx={{mt: 10}}>
                <Button variant="contained" onClick={handleSubmitAccount} disabled={error}>Submit</Button>
            </Grid>
        </Grid>
    )

}