import React, {Fragment, useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import Cookies from "js-cookie";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {routes, useGetDataById, usePatchData} from "../routes/api";
import {LoadingComponent} from "../util/loading";
import {OverflowTooltip, updateConfirmationMessage, updateData} from "../util/util";
import {
    Alert,
    AlertTitle,
    Box,
    CardActionArea,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    IconButton,
    SvgIcon,
    Switch,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {PositionMap} from "../components/map";
import ContentDetail from "../components/contentDetail";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {ApplicationRelationHeaders, CameraListHeaders, FirmwareRelationHeaders, UnitHeaders} from "../util/getFields";
import PopupDialog from "../components/popupDialog";
import RelationEdit from "../components/relationEdit";
import {applicationFields, cameraFields, firmwareFields, unitFields} from "../util/modelFields";
import {DeleteComponent, FactoryResetComponent} from "../components/confirmationDialog";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import ErrorDisplay from "../components/errorDisplay";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {green, grey, red, yellow} from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import {EnumContext} from "../components/enumContext";
import StatusList from "../components/statusList";
import WaitTimeStatus from "../components/waitTimeStatus";
import {ContentField} from "../components/editField";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import {ReactComponent as WifiUnknownIcon} from '../assets/icon/wifi_unknown.svg';
import VideocamIcon from "@mui/icons-material/Videocam";


export default function UnitDetail() {
    const token = Cookies.get('token');
    const permissions = Cookies.get('permissions') ?
        JSON.parse(Cookies.get('permissions')).unit :
        {create: false, edit: false, delete: false, view: false};
    const webAdmin = Cookies.get('webAdmin') === 'T';

    const {id} = useParams();
    const {enums} = useContext(EnumContext);
    const route = routes.unit;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [snackbarList, setSnackbarList] = useState([]);
    const [newData, setNewData] = useState({});
    const {data: detail, loading, statusCode, handleCall} = useGetDataById(token, route, id);
    const [wifi, setWifi] = useState(false);
    const [edit, setEdit] = useState(false);
    const [popup, setPopup] = useState({
        'camera': false,
        'firmware': false,
        'application': false,
    })
    const [resetId, setResetId] = useState(null);
    const [resetError, setResetError] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [deleteError, setDeleteError] = useState({error: false, message: null, status: null});

    const navigate = useNavigate();
    const location = useLocation();

    const {
        data: patchResp,
        statusCode: patchStatus,
        handleCall: handlePatch
    } = usePatchData(token, route, id);
    const [alert, setAlert] = useState(false);

    const handleClick = (camera) => {
        navigate(`/camera/${camera}`, {state: {from: location, unitSN: newData.data.u_sn, unitId: id}});
    };

    const handleFirmwareClick = (firmware) => {
        navigate(`/firmware/${firmware}`, {state: {from: location, unitSN: newData.data.u_sn, unitId: id}});
    }

    const handleApplicationClick = (application) => {
        navigate(`/application/${application}`, {state: {from: location, unitSN: newData.data.u_sn, unitId: id}});
    }

    const updateWifi = () => {
        handlePatch({body: {u_wifi: wifi}});
    }

    const wifiSwitch = () => {
        setWifi(w => !w);
    }

    const fetchDetail = async () => {
        try {
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handlePopupClose = (key) => {
        setPopup({...popup, [key]: false});
    }

    const handleMapClick = (unit) => {
        navigate(`/unit/${unit.id}`, {state: {from: location}});
    }

    const addSnackbar = (field, model, time) => {
        const id = new Date().getTime();
        setSnackbarList((prevSnackbars) => [
            ...prevSnackbars,
            {id, field, model, time},
        ]);
    };

    const handleClose = (id, event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarList((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.id !== id));
    };

    useEffect(() => {
        fetchDetail();
    }, []);

    useEffect(() => {
        if (statusCode > 400) {
            navigate('/unit');
        }
    }, [statusCode]);

    useEffect(() => {
        if (patchStatus >= 200 && patchStatus < 300) {
            setAlert(false);
            addSnackbar('Updating WiFi', 'Unit', '2 minutes')
            fetchDetail();
        } else if (patchStatus > 300) {
            setAlert(true);
        }
    }, [patchStatus, patchResp]);

    useEffect(() => {
        if (JSON.stringify(detail) !== '{}' && detail?.data) {
            let updatedUnitData = updateData(detail.data, UnitHeaders, 'u', enums);
            let updatedCamerasData = detail.Camera.map((camera) => updateData(camera, CameraListHeaders, 'c', enums));
            let updatedFirmware = updateData(detail.Firmware, FirmwareRelationHeaders, 'f', enums);
            let updatedApplication = updateData(detail.Application, ApplicationRelationHeaders, 'app', enums);
            let updatedConfirmationMessage = updateConfirmationMessage(detail.ConfirmationMessage);
            setNewData({
                data: updatedUnitData,
                Camera: updatedCamerasData,
                Firmware: updatedFirmware,
                Application: updatedApplication,
                ConfirmationMessage: updatedConfirmationMessage,
            });
            setResetId(detail.Camera.length > 0 ? detail.Camera[0].id : null)
            setWifi(Boolean(detail.data.u_wifi));
        }
    }, [detail]);

    if (loading || Object.keys(newData).length === 0) return <LoadingComponent/>;

    return <Grid container spacing={2} sx={{margin: 'auto', mt: 5, width: '80%'}} alignItems="stretch"
                 justifyContent="center">
        <Grid item xs={12}>
            <Collapse in={resetError}>
                <Alert severity="error"
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setResetError(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                       sx={{mb: 2}}
                >
                    <AlertTitle>Error</AlertTitle>
                    Could not perform factory reset at the time
                </Alert>
            </Collapse>
            <ErrorDisplay
                fields={unitFields}
                alert={deleteError.error}
                setAlert={(alert) => setDeleteError({...deleteError, error: alert})}
                response={deleteError.message}
                statusCode={deleteError.status}
            />
            <WaitTimeStatus
                snackbarList={snackbarList}
                handleClose={handleClose}
            />
        </Grid>
        <Grid item xs={12}>
            <NavigateBreadcrumbs alias='unit' display='Unit' id={newData.data.u_sn}/>
        </Grid>
        <Grid item xs={9} sx={{mb: 3}}>
            <Typography variant='h4'>Unit {newData.data.u_sn}</Typography>
        </Grid>
        <Grid item xs={3} sx={{mb: 3, display: "flex", justifyContent: "flex-end"}}>
            <FactoryResetComponent
                id={resetId}
                disabled={!resetId}
                setResetting={setResetting}
                navigate={navigate}
                setError={setResetError}
                refresh={fetchDetail}
                addSnackbar={addSnackbar}
            />
        </Grid>
        <Grid item xs={4} sm={3} sx={{mb: 5}}>
            {(permissions.delete || webAdmin) && <DeleteComponent
                alias='unit'
                id={id}
                navigate={navigate}
                setError={setDeleteError}
            />}
        </Grid>
        <Grid item xs={4} sm={6}/>
        {(permissions.edit || webAdmin) &&
            <Grid item xs={4} sm={3} key='related-camera' sx={{mb: 5, display: "flex", justifyContent: "flex-end"}}>
                <Button
                    variant="contained"
                    startIcon={<EditIcon/>}
                    onClick={() => (setPopup({...popup, camera: true}))}>
                    Related Cameras
                </Button>
            </Grid>
        }
        {resetting &&
            <Grid item xs={12} key='resetting' sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress/>
            </Grid>
        }
        <Grid item sm={12} xl={newData.Camera.length === 1 ? 7 : 5} key="unit-detail" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={(permissions.edit || webAdmin) &&
                        <IconButton key='edit' onClick={() => setEdit(!edit)}>
                            <EditIcon/>
                        </IconButton>
                    }
                    title='Details'
                />
                <CardContent>
                    <ContentDetail
                        alias='unit'
                        id={id}
                        data={newData.data}
                        edit={edit}
                        setEdit={setEdit}
                        refresh={fetchDetail}
                        fields={unitFields}
                    />
                </CardContent>
            </Card>
        </Grid>
        {newData.Camera.map((camera) =>
            <Grid item
                  xs={12}
                  md={newData.Camera.length === 1 ? 12 : 6}
                  xl={newData.Camera.length === 1 ? 5 : 3.5}
                  key={`camera-detail-${camera.c_sn}`}
                  sx={{display: 'flex'}}>
                <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                    <CardHeader
                        action={
                            <IconButton onClick={() => handleClick(camera.id)}>
                                <KeyboardDoubleArrowRightIcon/>
                            </IconButton>
                        }
                        title={`Camera ${camera.c_sn}`}
                    />
                    <CardContent>
                        <Grid container
                              spacing={1}
                              sx={{margin: 'auto', mb: 2}}
                              alignItems="center"
                              justifyContent="center"
                        >
                            {Object.entries(camera).filter((item) => (item[0] !== 'id')).map(([key, value]) => (
                                <Fragment key={key}>
                                    <Grid item xs={5} sx={{mb: 1}}>
                                        <Typography variant="body1" color={grey[700]}>
                                            {cameraFields.fields[key].display || key}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sx={{mb: 1}}>
                                        <ContentField
                                            field={cameraFields.fields[key]}
                                            alias={key}
                                            value={value}
                                        />
                                    </Grid>
                                </Fragment>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )}
        <Grid item xs={12} key="unit-icons">
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader title="Core Component Status"/>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        justifyContent: "space-around",
                        alignItems: 'center',
                    }}>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 2}}>
                            {detail.data.u_status === 'CNCTISSUE' || (detail.data.u_error && detail.data.u_error.length > 0) ?
                                <Tooltip title={`Status: Error`}>
                                    <ViewCarouselIcon style={{color: red[400], fontSize: 90}}/>
                                </Tooltip> :
                                <Tooltip title={`Status: ${enums.u_status.data[detail.data.u_status]}`}>
                                    {detail.data.u_status === 'NVCONNCTD' ?
                                        <ViewCarouselIcon style={{color: grey[400], fontSize: 90}}/> :
                                        <ViewCarouselIcon style={{color: green[300], fontSize: 90}}/>}
                                </Tooltip>

                            }
                            <Typography variant='subtitle1' sx={{marginTop: 1, color: 'grey'}}>
                                Unit Status
                            </Typography>
                        </Box>
                        <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} flexItem/>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 2}}>
                            {detail.data.u_status === 'CONNECTED' ?
                                <Tooltip title={`WIFI status: ${wifi ? 'Online' : 'Offline'}`}>
                                    {wifi ?
                                        <WifiIcon style={{color: green[300], fontSize: 90}}/> :
                                        <WifiOffIcon style={{color: grey[400], fontSize: 90}}/>
                                    }
                                </Tooltip> :
                                <Tooltip key='u_wifi' title={`WiFi status unknown`}>
                                    <SvgIcon style={{color: yellow[700], fontSize: 90}} key='wifi-yellow'>
                                        <WifiUnknownIcon/>
                                    </SvgIcon>
                                </Tooltip>
                            }
                            <Typography variant='subtitle1' sx={{marginTop: 1, color: 'grey'}}>
                                Wifi Status
                            </Typography>
                        </Box>
                        {detail.Camera.map((camera, _) => (
                            <Fragment key={`${camera.c_sn}-status`}>
                                <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} flexItem/>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    margin: 2
                                }}>
                                    {detail.data.u_status === 'NVCONNCTD' ?
                                        <Tooltip title='camera status unknown'>
                                            <VideocamIcon style={{color: yellow[700], fontSize: 90}}/>
                                        </Tooltip> :
                                        (camera.c_error && camera.c_error.length > 0 ?
                                            <Tooltip title='camera malfunctioning'>
                                                <VideocamIcon style={{color: red[400], fontSize: 90}}/>
                                            </Tooltip> :
                                            <Tooltip title='camera functional'>
                                                <VideocamIcon style={{color: green[300], fontSize: 90}}/>
                                            </Tooltip>)
                                    }
                                    <Typography variant='subtitle1' sx={{marginTop: 1, color: 'grey'}}>
                                        {camera.c_sn} Status
                                    </Typography>
                                </Box>
                            </Fragment>
                        ))}
                    </Box>
                    <StatusList data={newData.ConfirmationMessage}/>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} xl={4} key="unit-wifi">
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={(permissions.edit || webAdmin) && <Switch
                        color="success"
                        checked={wifi && detail.data.u_status === 'CONNECTED'}
                        disabled={!(detail.data.u_status === 'CONNECTED')}
                        onChange={wifiSwitch}
                    />}
                    title="Wifi Connection"/>
                <CardContent>
                    <Grid container
                          spacing={1}
                          sx={{margin: 'auto', mb: 2}}
                          alignItems="center"
                          justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <ErrorDisplay
                                alert={alert}
                                setAlert={setAlert}
                                response={patchResp}
                                fields={unitFields}
                                statusCode={patchStatus}
                                account
                            />
                        </Grid>
                        <Grid item xs={5} key='url' sx={{mb: 1}}>
                            <Typography variant="body1" color={grey[700]}>
                                Wifi SSID
                            </Typography>
                        </Grid>
                        <Grid item xs={7} key='url-value' sx={{mb: 1}}>
                            <OverflowTooltip followCursor>
                                <Typography variant="body1">
                                    {detail.data.u_url}
                                </Typography>
                            </OverflowTooltip>
                        </Grid>
                        <Grid item xs={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {(permissions.edit || webAdmin) && <Button
                                variant="contained"
                                size='small'
                                sx={{mt: 1}}
                                onClick={updateWifi}
                                disabled={wifi === detail.data.u_wifi}
                            >
                                Update Wifi
                            </Button>}
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} lg={6} xl={4} key="unit-firmware" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={webAdmin ?
                        <IconButton key='firmware-edit' onClick={() => (setPopup({...popup, firmware: true}))}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='Firmware'
                />
                {Object.keys(newData.Firmware).length === 0 ?
                    <CardContent key='no-firmware'>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No Related Firmware
                        </Typography>
                    </CardContent> :
                    <CardContent key={`firmware-${detail.Firmware.id}`}>
                        <CardActionArea onClick={webAdmin ? () => handleFirmwareClick(detail.Firmware.id) : null}>
                            <Grid container
                                  spacing={1}
                                  sx={{margin: 'auto', mb: 2}}
                                  alignItems="center"
                                  justifyContent="center"
                                  wrap="wrap"
                            >
                                {Object.entries(newData.Firmware).filter((item) => (item[0] !== 'id')).map(([key, value]) => (
                                    [
                                        <Grid item xs={5} key={key} sx={{mb: 1}}>
                                            <Typography variant="body1" color={grey[700]}>
                                                {firmwareFields.fields[key].display || key}
                                            </Typography>
                                        </Grid>,
                                        <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                            <OverflowTooltip followCursor>
                                                <Typography variant="body1">
                                                    {value}
                                                </Typography>
                                            </OverflowTooltip>
                                        </Grid>
                                    ]
                                ))}
                            </Grid>
                        </CardActionArea>
                    </CardContent>
                }
            </Card>
        </Grid>
        <Grid item xs={12} lg={6} xl={4} key="unit-application" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={webAdmin ?
                        <IconButton onClick={() => (setPopup({...popup, application: true}))}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='Application'
                />
                {Object.keys(newData.Application).length === 0 ?
                    <CardContent>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No Related Application
                        </Typography>
                    </CardContent> :
                    <CardContent>
                        <CardActionArea onClick={webAdmin ? () => handleApplicationClick(detail.Application.id) : null}>
                            <Grid container
                                  spacing={1}
                                  sx={{margin: 'auto', mb: 2}}
                                  alignItems="center"
                                  justifyContent="center"
                                  wrap="wrap"
                            >
                                {Object.entries(newData.Application).filter((item) => (item[0] !== 'id')).map(([key, value]) => (
                                    [
                                        <Grid item xs={5} key={key} sx={{mb: 1}}>
                                            <Typography variant="body1" color={grey[700]}>
                                                {applicationFields.fields[key].display || key}
                                            </Typography>
                                        </Grid>,
                                        <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                            <OverflowTooltip followCursor>
                                                <Typography variant="body1">
                                                    {value}
                                                </Typography>
                                            </OverflowTooltip>
                                        </Grid>
                                    ]
                                ))}
                            </Grid>
                        </CardActionArea>
                    </CardContent>
                }
            </Card>
        </Grid>
        <PopupDialog
            open={popup.camera}
            content={<RelationEdit
                fields={unitFields}
                alias='unit'
                display='Unit'
                id={id}
                relation='Camera'
                refresh={fetchDetail}
                setEdit={(value) => (setPopup({...popup, camera: value}))}
                addSnackbar={addSnackbar}
                defaultValue={newData.Camera.map((model) => ([model.id, model.c_sn]))}
            />}
            handleClose={() => handlePopupClose('camera')}
        />
        <PopupDialog
            open={popup.firmware}
            content={<RelationEdit
                fields={unitFields}
                alias='unit'
                display='Unit'
                id={id}
                relation='Firmware'
                refresh={fetchDetail}
                setEdit={(value) => (setPopup({...popup, firmware: value}))}
                addSnackbar={addSnackbar}
                defaultValue={
                    Object.keys(newData.Firmware).length === 0 ?
                        null :
                        [newData.Firmware.id, `${newData.Firmware.f_name} (${newData.Firmware.f_version})`]
                }
            />}
            handleClose={() => handlePopupClose('firmware')}
        />
        <PopupDialog
            open={popup.application}
            content={<RelationEdit
                fields={unitFields}
                alias='unit'
                display='Unit'
                id={id}
                relation='Application'
                refresh={fetchDetail}
                setEdit={(value) => (setPopup({...popup, application: value}))}
                defaultValue={
                    Object.keys(newData.Application).length === 0 ?
                        null :
                        [newData.Application.id, `${newData.Application.app_name} (${newData.Application.app_version})`]
                }
            />}
            handleClose={() => handlePopupClose('application')}
        />

        {(detail.data?.u_latitude && detail.data.u_longitude) &&
            <Grid item xs={12} key="map-location">
                <Card sx={{borderRadius: '16px', width: '100%', height: '40vh'}}>
                    <PositionMap units={[{...detail.data, Camera: detail.cameras}]} className="leaflet-container-mini"
                                 handleClick={handleMapClick}/>
                </Card>
            </Grid>
        }

    </Grid>
}