import Cookies from "js-cookie";
import {DataGrid} from "@mui/x-data-grid";
import {routes, useGetDataList, useGroupUpdateData, useSearch} from "../routes/api";
import React, {useContext, useEffect, useState} from "react";
import {LoadingComponent, NoDataComponent} from "../util/loading";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {PositionMap} from "../components/map";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {
    Box,
    ButtonGroup,
    Card,
    CardActionArea,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    SvgIcon
} from "@mui/material";
import {unitFields} from "../util/modelFields";
import CustomToolbar from "../components/gridToolbar";
import {UnitHeaders, UnitListHeaders, UnitMapHeaders} from "../util/getFields";
import {convertUnitData, PAGE_SIZE, updateData} from "../util/util";
import Typography from "@mui/material/Typography";
import RelationView from "../components/relationView";
import PopupDialog from "../components/popupDialog";
import ErrorDisplay from "../components/errorDisplay";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import {green, grey, red, yellow} from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import SearchBox from "../components/searchBox";
import {unitSearchFields} from "../util/searchFields";
import {EnumContext} from "../components/enumContext";
import WaitTimeStatus from "../components/waitTimeStatus";
import CircularProgress from "@mui/material/CircularProgress";
import MapIcon from '@mui/icons-material/Map';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import VideocamIcon from '@mui/icons-material/Videocam';
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {ReactComponent as WifiUnknownIcon} from '../assets/icon/wifi_unknown.svg';


export default function UnitList() {
    const token = Cookies.get('token');
    const permissions = Cookies.get('permissions') ?
        JSON.parse(Cookies.get('permissions')).unit :
        {create: false, edit: false, delete: false, view: false};
    const webAdmin = Cookies.get('webAdmin') === 'T';
    const route = routes.unit;
    const groupRoute = routes.unitgroup;
    const {enums} = useContext(EnumContext);

    const [snackbarList, setSnackbarList] = useState([]);
    const [updatedFile, setUpdatedFile] = useState('');
    const [noData, setNoData] = useState(false);
    const [errorOnly, setErrorOnly] = useState(sessionStorage.getItem('errorOnly') !== 'F');
    const [errorCheckBlock, setErrorCheckBlock] = useState(false);
    const [viewHeaders, setViewHeaders] = useState([
        ...UnitListHeaders.filter(key => !['id', 'u_cam_error'].includes(key)).map((key) =>
            ({
                field: key,
                headerName: unitFields.fields[key].display,
                type: unitFields.fields[key].type === 'boolean' ? 'boolean' : 'string',
                minWidth: 100,
                flex: 1,
                hide: key === 'id',
                hideable: key !== 'u_sn',
                valueGetter: (value) => (Array.isArray(value) ?
                        value.reduce((acc, item) => (acc ? `${acc}, ${item}` : item), '') :
                        value
                )
            })
        ),
        {
            field: 'iconStatus',
            headerName: 'Statuses',
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            disableExport: true,
            maxWidth: 175,
            renderCell: (params) => (
                <Box
                    key='icon_status'
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {params.row.u_status === 'Connection issue' ?
                        <Tooltip
                            key='u_status_error'
                            title={
                                <>
                                    <Typography fontSize="inherit">
                                        Unit Connection Issue
                                    </Typography>
                                </>
                            }>
                            <ViewCarouselIcon style={{color: red[400]}} key='u-status-red'/>
                        </Tooltip> :
                        (params.row.u_error && params.row.u_error.length > 0 ?
                                <Tooltip
                                    key='u_status_error'
                                    title={
                                        <>
                                            <Typography fontSize="inherit">
                                                Unit errors:
                                            </Typography>
                                            {params.row.u_error.map((item) => (
                                                <Typography fontSize="inherit" key={item}>
                                                    {item}
                                                </Typography>
                                            ))}
                                        </>
                                    }>
                                    <ViewCarouselIcon style={{color: red[400]}} key='u-status-red'/>
                                </Tooltip> :
                                <Tooltip key='u_status_ok' title={`Unit status: ${params.row.u_status}`}>
                                    {params.row.u_status === 'Never connected' ?
                                        <ViewCarouselIcon style={{color: grey[400]}} key='u-status-grey'/> :
                                        <ViewCarouselIcon style={{color: green[300]}} key='u-status-green'/>}
                                </Tooltip>
                        )
                    }
                    {params.row.u_status === 'Connected' ?
                        <Tooltip key='u_wifi' title={`WiFi ${params.row.u_wifi ? 'Online' : 'Offline'}`}>
                            {params.row.u_wifi ?
                                <WifiIcon style={{color: green[300]}} key='wifi-green'/> :
                                <WifiOffIcon style={{color: grey[400]}} key='wifi-grey'/>
                            }
                        </Tooltip> :
                        <Tooltip key='u_wifi' title={`WiFi Status Unknown`}>
                            <SvgIcon style={{color: yellow['700']}} key='wifi-yellow'>
                                <WifiUnknownIcon/>
                            </SvgIcon>
                        </Tooltip>
                    }
                    <Tooltip
                        key='c_errors'
                        title={(params.row.u_camera && params.row.u_camera.length === 0) ?
                            'No Camera' :
                            (params.row.u_cam_issue ?
                                'Camera(s) Malfunctioning' :
                                'All Camera(s) Fully Functional')}>
                        {(params.row.u_camera && params.row.u_camera.length === 0) ?
                            <VideocamIcon style={{color: grey[400]}} key='c-status-grey'/> :
                            (params.row.u_cam_issue ?
                                <VideocamIcon style={{color: red[400]}} key='c-status-red'/> :
                                <VideocamIcon style={{color: green[300]}} key='c-status-green'/>)
                        }
                    </Tooltip>
                </Box>
            ),
        }
    ]);


    const [rowSelected, setRowSelected] = useState([]);
    const [mapView, setMapView] = useState(sessionStorage.getItem('mapView') !== 'F');

    const [unitData, setUnitData] = useState({});
    const [currUnit, setCurrUnit] = useState(null);
    const [firmwareSelect, setFirmwareSelect] = useState(false);
    const [applicationSelect, setApplicationSelect] = useState(false);
    const [aiModelSelect, setAIModelSelect] = useState(false);
    const [firmwareId, setFirmwareId] = useState(null);
    const [applicationId, setApplicationId] = useState(null);
    const [aiModelId, setAIModelId] = useState(null);

    const {
        data: allData,
        loading: allLoading,
        statusCode: allStatus,
        handleCall: allCall
    } = useGetDataList(token, route);

    const [alert, setAlert] = useState(false);
    const [mapAvailable, setMapAvailable] = useState(false);
    const [newData, setNewData] = useState([]);
    const [filteredMapAvailable, setFilteredMapAvailable] = useState(false);
    const [totalPages, setTotalPages] = useState(null);

    const {
        data: searchData,
        loading: searchLoading,
        statusCode: searchStatus,
        handleCall: searchCall
    } = useSearch(token, route);

    const {data: groupResp, statusCode: groupStatus, handleCall: groupCall} = useGroupUpdateData(token, groupRoute);

    const location = useLocation();
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        page: -1,
        pageSize: PAGE_SIZE,
    });

    const [searchParams, setSearchParams] = useState({});
    const [searching, setSearching] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        u_pole: false,
        u_status: false,
        u_latitude: false,
        u_longitude: false,
        u_timeAdded: false,
        u_part: false,
        u_wifi: false,
        u_cam_error: false,
        u_error: false,
        u_firmware: false,
        u_application: false,
    });

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (['unit', 'camera'].includes(location?.state?.from?.pathname?.split('/')[1]) || !location?.state?.from?.pathname) {
            setPaginationModel({...paginationModel, page: Number(sessionStorage.getItem('unitPage'))});
            setSearchParams(JSON?.parse(sessionStorage.getItem('unitSearchParams')) || {});
            setSearching(sessionStorage.getItem('unitSearching') === 'T');
            setOpenSearch(sessionStorage.getItem('unitOpenSearch') === 'T');
            setColumnVisibilityModel(JSON?.parse(sessionStorage.getItem('unitColumns')) || {
                u_pole: false,
                u_status: false,
                u_latitude: false,
                u_longitude: false,
                u_timeAdded: false,
                u_part: false,
                u_wifi: false,
                u_cam_error: false,
                u_error: false,
                u_firmware: false,
                u_application: false,
            });
        } else {
            setPaginationModel({...paginationModel, page: 0});
            sessionStorage.setItem('unitPage', '0');
            sessionStorage.setItem('unitSearchParams', '{}');
            sessionStorage.setItem('unitSearching', 'F');
            sessionStorage.setItem('unitOpenSearch', 'F');
            sessionStorage.setItem('unitColumns', JSON.stringify({
                u_pole: false,
                u_status: false,
                u_latitude: false,
                u_longitude: false,
                u_timeAdded: false,
                u_part: false,
                u_wifi: false,
                u_cam_error: false,
                u_error: false,
                u_firmware: false,
                u_application: false,
            }));
        }
    }, []);

    const handleReset = () => {
        setColumnVisibilityModel({
            u_pole: false,
            u_status: false,
            u_latitude: false,
            u_longitude: false,
            u_timeAdded: false,
            u_part: false,
            u_wifi: false,
            u_cam_error: false,
            u_error: false,
            u_firmware: false,
            u_application: false,
        });
        sessionStorage.setItem('unitColumns', JSON.stringify({
            u_pole: false,
            u_status: false,
            u_latitude: false,
            u_longitude: false,
            u_timeAdded: false,
            u_part: false,
            u_wifi: false,
            u_cam_error: false,
            u_error: false,
            u_firmware: false,
            u_application: false,
        }));
    };

    const fetchData = async () => {
        try {
            if (searching) {
                const params = errorOnly ?
                    {...searchParams, page: paginationModel.page + 1, show_errors: true} :
                    {...searchParams, page: paginationModel.page + 1};
                await searchCall({params: params});
            } else {
                const params = errorOnly ?
                    {page: paginationModel.page + 1, show_errors: true} :
                    {page: paginationModel.page + 1};
                await allCall({params: params});
            }
        } catch (error) {
            console.error('Error fetching unit data:', error);
        }
    }

    useEffect(() => {
        paginationModel.page >= 0 && fetchData();
    }, [paginationModel, errorOnly]);

    useEffect(() => {
        if (allStatus === 204 || allData?.count === 0 || allData?.results?.length === 0) {
            if (errorOnly) {
                setNoData(false);
                setNewData([]);
            } else {
                setNoData(true);
            }
            setMapAvailable(false);
            sessionStorage.setItem('mapView', 'F');
            setMapView(false);
        } else if (allData?.results?.length > 0) {
            let filteredLength = allData.results.reduce((acc, unit, _) => ((unit.u_latitude && unit.u_longitude) ? acc + 1 : acc), 0);
            setMapAvailable(filteredLength > 0);
            if (filteredLength <= 0) {
                sessionStorage.setItem('mapView', 'F');
                setMapView(false);
            }
            setUnitData(convertUnitData(allData.results.map(data => updateData(data, UnitMapHeaders, 'u', enums)), unitFields.fields));
            setNewData(allData.results.map(data => updateData(data, null, 'u', enums)));
            setNoData(false);
            setTotalPages(Math.ceil(allData.count / PAGE_SIZE));
        } else {
            setNoData(false);
        }
    }, [allData, allStatus]);


    useEffect(() => {
        if (searchStatus === 204 || searchData?.count === 0 || searchData?.results?.length === 0) {
            setFilteredMapAvailable(false);
            sessionStorage.setItem('mapView', 'F');
            setMapView(false);
        } else if (searchData.results?.length > 0) {
            let filteredLength = searchData.results.reduce((acc, unit, _) => ((unit.u_latitude && unit.u_longitude) ? acc + 1 : acc), 0);
            const updatedData = searchData.results.map(data => updateData(data, null, 'u', enums));
            setFilteredMapAvailable(filteredLength > 0);
            if (filteredLength <= 0) {
                sessionStorage.setItem('mapView', 'F');
                setMapView(false);
            }
            setFilteredData(updatedData);
            setTotalPages(Math.ceil(searchData.count / PAGE_SIZE));
        } else {
            setFilteredData([]);
            setTotalPages(0);
        }
    }, [searchData, searchStatus]);

    const handleColumnResize = (params) => {
        setViewHeaders((prevColumns) =>
            prevColumns.map((col) =>
                col.field === params.colDef.field
                    ? {...col, width: params.width}
                    : col
            )
        );
    };

    const handleRowClick = (param, event) => {
        event.preventDefault();
        window.history.replaceState({from: location,}, '');
        navigate(`/unit/${param.row.id}`, {state: {from: location}});
    };

    const handleSwitchView = () => {
        sessionStorage.setItem('mapView', !mapView ? 'T' : 'F');
        setMapView(!mapView);
    }

    const handleEntry = (event) => {
        event.preventDefault();
        navigate(`/unit/new`, {state: {from: location}});
    };

    const handleCameraEntry = (event) => {
        event.preventDefault();
        navigate(`/camera/new`, {state: {from: location}});
    };
    const handleClick = (id) => {
        window.history.replaceState({from: location}, '');
        navigate(`/unit/${id}`, {state: {from: location}});
    };

    const handleMapClick = (unit) => {
        setCurrUnit(unit.id);
    }

    const handlePageSelect = (e) => {
        setPaginationModel((pm) => ({...pm, page: e.target.value}));
        sessionStorage.setItem('unitPage', e.target.value);
    }

    const handlePaginationModelUpdate = (model, details) => {
        setPaginationModel(model);
        sessionStorage.setItem('unitPage', model.page);
    }

    const handleSearchParams = (params) => {
        sessionStorage.setItem('unitSearchParams', JSON.stringify(params));
        setSearchParams(params);
    }

    const handleColumnVisibilityLabel = (columns) => {
        sessionStorage.setItem('unitColumns', JSON.stringify(columns));
        setColumnVisibilityModel(columns);
    }

    const handleFirmwareRelationChange = (newValue, key, multiple, limit) => {
        if (multiple) {
            if (!limit || !newValue || newValue.length <= limit) {
                setFirmwareId(newValue.map((item) => item[0]));
            }
        } else if (!newValue) {
            setFirmwareId(null);
        } else {
            setFirmwareId(newValue[0]);
        }
    }

    const handleApplicationRelationChange = (newValue, key, multiple, limit) => {
        if (multiple) {
            if (!limit || !newValue || newValue.length <= limit) {
                setApplicationId(newValue.map((item) => item[0]));
            }
        } else if (!newValue) {
            setApplicationId(null);
        } else {
            setApplicationId(newValue[0]);
        }
    }
    const handleAIModelRelationChange = (newValue, key, multiple, limit) => {
        if (multiple) {
            if (!limit || !newValue || newValue.length <= limit) {
                setAIModelId(newValue.map((item) => item[0]));
            }
        } else if (!newValue) {
            setAIModelId(null);
        } else {
            setAIModelId(newValue[0]);
        }
    }
    const handleFirmwareNew = (value, key, multiple, limit) => {
        if (!multiple) {
            setFirmwareId(value);
        } else {
            if (firmwareId) {
                if (firmwareId.length < limit) {
                    setFirmwareId([...firmwareId, value]);
                }
            } else {
                setFirmwareId([value]);
            }
        }
    };

    const handleApplicationNew = (value, key, multiple, limit) => {
        if (!multiple) {
            setApplicationId(value);
        } else {
            if (applicationId) {
                if (applicationId.length < limit) {
                    setApplicationId([...applicationId, value]);
                }
            } else {
                setApplicationId([value]);
            }
        }
    };

    const handleAIModelNew = (value, key, multiple, limit) => {
        if (!multiple) {
            setAIModelId(value);
        } else {
            if (aiModelId) {
                if (aiModelId.length < limit) {
                    setAIModelId([...aiModelId, value]);
                }
            } else {
                setAIModelId([value]);
            }
        }
    };

    const handleFirmwareSubmit = async () => {
        const body = {units: rowSelected, firmware: firmwareId};
        await groupCall({body: body});
        setFirmwareSelect(false);
        setUpdatedFile('Firmware');
    }

    const handleApplicationSubmit = async () => {
        const body = {units: rowSelected, application: applicationId};
        await groupCall({body: body});
        setApplicationSelect(false);
        setUpdatedFile('Application');
    }

    const handleAIModelSubmit = async () => {
        const body = {units: rowSelected, aimodel: aiModelId};
        await groupCall({body: body});
        setAIModelSelect(false);
        setUpdatedFile('AI Model');
    }

    const handleSearch = async () => {
        setFilteredData([]);
        setSearching(true);
        sessionStorage.setItem('unitSearching', 'T');
        setPaginationModel((pm) => ({...pm, page: 0}));
        sessionStorage.setItem('unitPage', '0');
    }

    const handleOpenSearch = () => {
        setOpenSearch(true);
        sessionStorage.setItem('unitOpenSearch', 'T');
    }

    const handleCancelSearch = () => {
        if (searching) {
            sessionStorage.setItem('unitPage', '0');
            setPaginationModel((pm) => ({...pm, page: 0}));
        }
        setOpenSearch(false);
        sessionStorage.setItem('unitOpenSearch', 'F');
        setSearching(false);
        sessionStorage.setItem('unitSearching', 'F');
        setSearchParams({});
        sessionStorage.setItem('unitSearchParams', '{}');
        setFilteredData([]);
    }

    const addSnackbar = (field, model, time) => {
        const id = new Date().getTime();
        setSnackbarList((prevSnackbars) => [
            ...prevSnackbars,
            {id, field, model, time},
        ]);
    };

    const handleClose = (id, event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarList((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.id !== id));
    };

    const handleSetError = (e) => {
        e.preventDefault();
        setPaginationModel((pm) => ({...pm, page: 0}));
        sessionStorage.setItem('unitPage', '0');
        setErrorOnly(e.target.checked);
        sessionStorage.setItem('errorOnly', e.target.checked ? 'T' : 'F');
        setErrorCheckBlock(true);
    }

    useEffect(() => {
        if (errorCheckBlock) {
            const timer = setTimeout(() => {
                setErrorCheckBlock(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [errorCheckBlock]);

    useEffect(() => {
        if (groupStatus === 202) {
            setAlert(false);
            addSnackbar(`Batch updating ${updatedFile}`, 'Units', '10 minutes');
        } else if (groupStatus === 207) {
            setAlert(true);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        setUpdatedFile('');
    }, [groupResp, groupStatus]);

    if ((!searching && allLoading) || (searching && searchLoading)) return <LoadingComponent/>;

    if (noData) return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justify="center"
        >
            <Grid item xs={12}/>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='unit' display='Unit'/>
            </Grid>
            <Grid item xs={8}>
                <Typography variant='h4'>Units</Typography>
            </Grid>
            <Grid item xs={4} sx={{display: "flex", justifyContent: "flex-end"}}>
                {(permissions.create || webAdmin) &&
                    <ButtonGroup disableElevation>
                        <Button component="label"
                                variant="outlined"
                                onClick={handleCameraEntry}
                                startIcon={<AddIcon/>}
                        >
                            New Camera
                        </Button>
                        <Button component="label"
                                variant="contained"
                                onClick={handleEntry}
                                startIcon={<AddIcon/>}
                        >
                            New Unit
                        </Button>
                    </ButtonGroup>
                }
            </Grid>
            <Grid item xs={9} sx={{display: "flex", justifyContent: "flex-start"}}>
                <FormControlLabel
                    control={<Checkbox/>}
                    disabled={errorCheckBlock}
                    checked={errorOnly}
                    onChange={handleSetError}
                    label={<Typography variant='subtitle1' sx={{
                        color: errorCheckBlock ? grey[500] : grey[800],
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {errorCheckBlock && (
                            <CircularProgress size={18} sx={{marginRight: 1, color: grey[500]}}/>
                        )}
                        Show Only Units with Issues</Typography>}
                />
            </Grid>
            <Grid item xs={12}>
                <NoDataComponent/>
            </Grid>
        </Grid>
    )

    return (
        <Grid
            container
            spacing={2}
            sx={{margin: 'auto', mt: 5, width: '80%'}}
            justifyContent="space-between"
        >
            <Grid item xs={12}>
                <ErrorDisplay
                    alert={alert}
                    setAlert={setAlert}
                    response={groupResp}
                    statusCode={groupStatus}
                    batch
                />
                <WaitTimeStatus
                    snackbarList={snackbarList}
                    handleClose={handleClose}
                />
            </Grid>
            <Grid item xs={12}>
                <NavigateBreadcrumbs alias='unit' display='Unit'/>
            </Grid>
            <Grid item xs={8}>
                <Typography variant='h4'>Units</Typography>
            </Grid>
            <Grid item xs={4} sx={{display: "flex", justifyContent: "flex-end"}}>
                {(permissions.create || webAdmin) &&
                    <ButtonGroup disableElevation>
                        <Button component="label"
                                variant="outlined"
                                onClick={handleCameraEntry}
                                startIcon={<AddIcon/>}
                        >
                            New Camera
                        </Button>
                        <Button component="label"
                                variant="contained"
                                onClick={handleEntry}
                                startIcon={<AddIcon/>}
                        >
                            New Unit
                        </Button>
                    </ButtonGroup>
                }
            </Grid>
            <Grid item xs={9} sx={{display: "flex", justifyContent: "flex-start"}}>
                <FormControlLabel
                    control={<Checkbox/>}
                    disabled={errorCheckBlock}
                    checked={errorOnly}
                    onChange={handleSetError}
                    label={<Typography variant='subtitle1' sx={{
                        color: errorCheckBlock ? grey[500] : grey[800],
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {errorCheckBlock && (
                            <CircularProgress size={18} sx={{marginRight: 1, color: grey[500]}}/>
                        )}
                        Show Only Units with Issues</Typography>}
                />
            </Grid>
            <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    component="label"
                    onClick={openSearch ? handleCancelSearch : handleOpenSearch}
                    startIcon={<ManageSearchIcon/>}
                >
                    {openSearch ? "Cancel" : "Filters"}
                </Button>
            </Grid>
            {openSearch && <SearchBox
                prefix='u'
                searchParams={searchParams}
                setSearchParams={handleSearchParams}
                searchFields={unitSearchFields}
                sendSearch={handleSearch}
                sx={{margin: 'auto', mt: 2, mb: 5, maxWidth: '100%'}}
            />}
            <Grid item xs={6} sm={9}>
                {!(mapView && ((!searching && mapAvailable) || (searching && filteredMapAvailable))) && webAdmin &&
                    <ButtonGroup disabled={rowSelected.length <= 0} sx={{mb: 1}}>
                        <Button startIcon={<EditIcon/>} onClick={() => setFirmwareSelect(true)}>
                            Firmware
                        </Button>
                        <Button startIcon={<EditIcon/>} onClick={() => setApplicationSelect(true)}>
                            Application
                        </Button>
                        <Button startIcon={<EditIcon/>} onClick={() => setAIModelSelect(true)}>
                            AI Model
                        </Button>
                    </ButtonGroup>
                }
            </Grid>
            <Grid item xs={6} sm={3} sx={{display: "flex", justifyContent: "flex-end", mb: 1}}>
                <Button
                    onClick={handleSwitchView}
                    disabled={!((!searching && mapAvailable) || (searching && filteredMapAvailable))}
                    startIcon={(mapView && ((!searching && mapAvailable) || (searching && filteredMapAvailable))) ?
                        <FormatListNumberedIcon/> : <MapIcon/>}>
                    {`switch to ${(mapView && ((!searching && mapAvailable) || (searching && filteredMapAvailable))) ? "list" : "map"} view`}
                </Button>
            </Grid>
            {searching && <Grid item xs={12}>
                <Typography variant='h6'>
                    Filtered Results:
                </Typography>
            </Grid>}
            {mapView && ((!searching && mapAvailable) || (searching && filteredMapAvailable)) ?
                <Grid item xs={12}>
                    <Typography variant='overline' sx={{display: "flex", justifyContent: "center", mb: 1}}>
                        units without latitude and longitude are not shown on map
                    </Typography>
                    <Card sx={{
                        borderRadius: '16px',
                        width: '100%',
                        height: '80vh',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <Box sx={{flex: 2, height: '100%'}}>
                            <PositionMap units={searching ? filteredData : newData}
                                         handleClick={handleMapClick} setMapView={setMapView}/>
                        </Box>
                        {currUnit && <Card sx={{borderRadius: '0px', flex: 1}}>
                            <CardHeader
                                title='Unit Detail'
                                action={
                                    <IconButton onClick={() => setCurrUnit(null)}>
                                        <CloseIcon/>
                                    </IconButton>
                                }
                            />
                            <CardActionArea onClick={() => handleClick(currUnit)}
                                            sx={{maxHeight: '90%', overflowY: 'auto'}}>
                                <Grid container
                                      spacing={1}
                                      sx={{margin: 'auto', mb: 2, width: '90%'}}
                                      alignItems="center"
                                      justifyContent="center"
                                >
                                    {Object.entries(unitData[String(currUnit)]).map(([key, value]) => (
                                        [
                                            <Grid item xs={5} key={key} sx={{mb: 1}}>
                                                <Typography variant="body1" color={grey[700]}>
                                                    {UnitHeaders[key] || key}
                                                </Typography>
                                            </Grid>,
                                            <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                                <Typography variant="body1">
                                                    {value}
                                                </Typography>
                                            </Grid>
                                        ]
                                    ))}
                                </Grid>
                            </CardActionArea>
                        </Card>}
                    </Card>
                </Grid> :
                <>
                    <Grid item xs={12}>
                        <DataGrid
                            disableColumnFilter
                            disableDensitySelector
                            columns={viewHeaders}
                            onColumnResizeCommitted={handleColumnResize}
                            rows={searching ? filteredData : newData}
                            rowCount={(searching ? searchData.count : allData.count) || 0}
                            slots={{toolbar: () => <CustomToolbar handleReset={handleReset}/>}}
                            pagination
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelUpdate}
                            pageSizeOptions={[PAGE_SIZE]}
                            autoHeight={true}
                            rowHeight={80}
                            onRowClick={handleRowClick}
                            checkboxSelection
                            onRowSelectionModelChange={(model) => setRowSelected(model)}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={handleColumnVisibilityLabel}
                            slotProps={{
                                columnsManagement: {
                                    disableResetButton: true,
                                },
                            }}
                        />
                    </Grid>
                    <PopupDialog
                        open={firmwareSelect}
                        display="Select Firmware"
                        content={<>
                            <RelationView
                                alias='unit'
                                fields={unitFields.relations}
                                handleChange={handleFirmwareRelationChange}
                                handleNew={handleFirmwareNew}
                                relationName={['u_firmware']}
                                currentValue={{u_firmware: firmwareId}}
                            />
                            <Button onClick={handleFirmwareSubmit} variant="contained">Submit</Button>
                        </>}
                        handleClose={() => setFirmwareSelect(false)}
                    />
                    <PopupDialog
                        open={applicationSelect}
                        display="Select Application"
                        content={<>
                            <RelationView
                                alias='unit'
                                fields={unitFields.relations}
                                handleChange={handleApplicationRelationChange}
                                handleNew={handleApplicationNew}
                                relationName={['u_application']}
                                currentValue={{u_application: applicationId}}
                            />
                            <Button onClick={handleApplicationSubmit} variant="contained">Submit</Button>
                        </>}
                        handleClose={() => setApplicationSelect(false)}
                    />
                    <PopupDialog
                        open={aiModelSelect}
                        display="Select AI Model"
                        content={<>
                            <RelationView
                                alias='camera'
                                fields={unitFields.relations}
                                handleChange={handleAIModelRelationChange}
                                handleNew={handleAIModelNew}
                                relationName={['c_model']}
                                currentValue={{c_model: aiModelId}}
                            />
                            <Button onClick={handleAIModelSubmit} variant="contained">Submit</Button>
                        </>}
                        handleClose={() => setAIModelSelect(false)}
                    />
                </>
            }
            {Boolean(totalPages) &&
                <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end", alignItems: 'center'}}>
                    <Typography sx={{mr: 1}}>Page: </Typography>
                    <Select
                        name='Page'
                        key='page'
                        onChange={handlePageSelect}
                        value={paginationModel.page}
                        sx={{minWidth: 70}}
                    >
                        {Array.from({length: totalPages}, (_, index) => (
                            <MenuItem key={index} value={index}>
                                {index + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>}
        </Grid>
    )
}