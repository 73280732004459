import React from "react";
import AlertBoard from "../components/alert";
import Box from "@mui/material/Box";

export default function Dashboard() {
    return <Box
        key='main-box'
        sx={{
            mt: 10,
            ml: 10,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: "flex-start",
            minWidth: '95%',
            height: '100%'
        }}>
        <AlertBoard/>

    </Box>
}