export const unitFields = {
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        u_sn: {
            alias: 'u_sn',
            display: 'Serial Number',
            type: 'text',
            placeholder: 'GS500-S0-2-2401-0001',
            helperText: 'Format: GS500-[S,L][0,1,2]-[1,2]-[yyww]-[xxxx]',
            regexPattern: '^GS500-[SL][012]-[12]-\\d{4}-\\d{4}$',
            required: true,
            create: true,
            edit: false,
        },
        u_active: {
            alias: 'u_active',
            display: 'Is Unit Active',
            type: 'boolean',
            defaultValue: true,
            placeholder: '',
            required: false,
            create: true,
            edit: true,
        },
        u_part: {
            alias: 'u_part',
            display: 'Part Number',
            type: 'text',
            placeholder: 'Less than 128 characters',
            regexPattern: '^.{0,128}$',
            required: false,
            create: true,
            edit: true,
        },
        u_url: {
            alias: 'u_url',
            display: 'Wifi SSID',
            type: 'text',
            placeholder: 'Less than 128 characters',
            regexPattern: '^.{0,128}$',
            required: false,
            create: true,
            edit: true,
        },
        u_latitude: {
            alias: 'u_latitude',
            display: 'Location Latitude',
            type: 'float',
            helperText: 'Please enter a valid number between -90 and 90, up to 5 digits after the decimal point',
            regexPattern: '^(-?(90(\\.0{1,5})?|[0-8]?\\d(\\.\\d{1,5})?))$',
            required: false,
            create: true,
            edit: true,
        },
        u_longitude: {
            alias: 'u_longitude',
            display: 'Location Longitude',
            type: 'float',
            helperText: 'Please enter a valid number between -180 and 180, up to 5 digits after the decimal point',
            regexPattern: '^(-?(180(\\.0{1,5})?|1[0-7]\\d(\\.\\d{1,5})?|[0-9]?\\d(\\.\\d{1,5})?))$',
            required: false,
            create: true,
            edit: true,
        },
        u_pole: {
            alias: 'u_pole',
            display: 'Light Pole ID',
            type: 'text',
            placeholder: 'Less than 32 characters',
            regexPattern: '^.{0,32}$',
            required: false,
            create: true,
            edit: true,
        },
        u_notes: {
            alias: 'u_notes',
            display: 'Notes',
            type: 'text',
            placeholder: '',
            required: false,
            regexPattern: null,
            multiline: true,
            create: true,
            edit: true,
        },
        u_temp: {
            alias: 'u_temp',
            display: 'Unit Temperature (°C)',
            type: 'text',
            create: false,
            edit: false
        },
        u_hdd: {
            alias: 'u_hdd',
            display: 'Free Disk Space (GB)',
            type: 'text',
            create: false,
            edit: false
        },
        u_wifi: {
            alias: 'u_wifi',
            display: 'Wifi Status',
            type: 'boolean',
            create: false,
            edit: false
        },
        u_status: {
            alias: 'u_status',
            display: 'Status',
            type: 'enum',
            create: false,
            edit: false
        },
        u_error: {
            alias: 'u_error',
            display: 'Error(s)',
            type: 'enum',
            multiple: true,
            create: false,
            edit: false
        },
        u_cam_error: {
            alias: 'u_cam_error',
            display: 'Camera Status',
            type: 'boolean',
            create: false,
            edit: false
        },
        u_timeAdded: {
            alias: 'u_timeAdded',
            display: 'Time Added',
            type: 'datetime',
            create: false,
            edit: false
        },
        u_timeLastSynced: {
            alias: 'u_timeLastSynced',
            display: 'Last Sync Time',
            type: 'datetime',
            create: false,
            edit: false
        },
        u_camera: {
            alias: 'u_camera',
            display: 'Camera(s)',
            type: 'relation',
            create: false,
            edit: false
        },
        u_firmware: {
            alias: 'u_firmware',
            display: 'Firmware',
            type: 'relation',
            create: false,
            edit: false
        },
        u_application: {
            alias: 'u_application',
            display: 'Application',
            type: 'relation',
            create: false,
            edit: false
        },
    },
    relations: {
        Firmware: {
            is_property: false,
            model: 'Firmware',
            alias: 'u_firmware',
            display: 'Unit Firmware',
            placeholder: '',
            required: false,
            admin: true,
            multiple: false
        },
        Application: {
            is_property: false,
            model: 'Application',
            alias: 'u_application',
            display: 'Unit Application',
            placeholder: '',
            required: false,
            admin: true,
            multiple: false
        },
        Camera: {
            is_property: false,
            model: 'Camera',
            alias: 'u_camera',
            display: 'Cameras Attached',
            helperText: 'limit 2 per unit',
            required: false,
            admin: false,
            multiple: true,
            limit: 2
        },
    },
    prefix: 'u',
    display: 'Unit'
}

export const cameraFields = {
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        c_sn: {
            alias: 'c_sn',
            display: 'Serial Number',
            type: 'text',
            placeholder: 'CA500-MIPI-2401-0001',
            helperText: 'Format: CA500-MIPI-[yyww]-[xxxx]',
            regexPattern: '^CA500-MIPI-\\d{4}-\\d{4}$',
            required: true,
            create: true,
            edit: false,

        },
        c_active: {
            alias: 'c_active',
            display: 'Is Camera Active',
            type: 'boolean',
            placeholder: '',
            defaultValue: true,
            required: false,
            create: true,
            edit: true,
        },
        c_sensor: {
            alias: 'c_sensor',
            display: 'Sensor Serial Number',
            type: 'text',
            placeholder: 'Less than 64 characters',
            regexPattern: '^.{0,64}$',
            required: false,
            create: true,
            edit: false,
        },
        c_part: {
            alias: 'c_part',
            display: 'Part Number',
            type: 'text',
            placeholder: 'Less than 128 characters',
            regexPattern: '^.{0,128}$',
            required: false,
            create: true,
            edit: true
        },
        c_order: {
            alias: 'c_order',
            display: 'Camera Order in Unit',
            type: 'int',
            placeholder: '',
            required: false,
            create: true,
            edit: false,
        },
        last_update_time: {
            alias: 'last_updated_date',
            display: 'Last Update Time',
            type: 'datetime',
            create: false,
            edit: false
        },
        c_status: {
            alias: 'c_status',
            display: 'Status',
            type: 'enum',
            create: false,
            edit: false
        },
        c_error: {
            alias: 'c_error',
            display: 'Error(s)',
            type: 'enum',
            multiple: true,
            create: false,
            edit: false
        },
        c_timeAdded: {
            alias: 'c_timeAdded',
            display: 'Time Added',
            type: 'datetime',
            create: false,
            edit: false
        },
        c_coordinates: {
            alias: 'c_coordinates',
            display: 'Coordinates',
            type: 'list',
            create: false,
            edit: false
        },
        c_model: {
            alias: 'c_model',
            display: 'AI Model',
            type: 'text',
            create: false,
            edit: false
        },
    },
    relations: {
        'AIModel': {
            is_property: false,
            model: 'AIModel',
            alias: 'c_model',
            display: 'Camera AI Model',
            placeholder: '',
            required: false,
            admin: true,
            multiple: false
        },
        'CameraProperty': {
            is_property: true,
            model: 'CameraProperty',
            alias: 'c_property',
            display: 'Camera Additional Information',
            placeholder: '',
            required: false,
            admin: false,
            multiple: true,
            prefix: 'cp'
        },
    },
    prefix: 'c',
    display: 'Camera'
}

export const accountFields = {
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        as_admin: {
            alias: 'as_admin',
            display: 'Is Org Admin',
            type: 'boolean',
            placeholder: '',
            required: false,
            defaultValue: false,
            create: true,
            edit: true,
        },
        a_type: {
            alias: 'a_type',
            display: 'Account type',
            type: 'text',
            placeholder: 'Less than 50 characters',
            helperText: 'Only letters, numbers, underscores, and hyphens',
            regexPattern: '^[\\w-]{0,50}$',
            required: false,
            create: false,
            edit: false,
        },
        a_phone: {
            alias: 'a_phone',
            display: 'Phone Number',
            type: 'text',
            placeholder: null,
            helperText: 'Format: 123-456-7890',
            regexPattern: '^[\\w-]{0,15}$',
            required: false,
            create: true,
            edit: true,
        }
    },
    relations: {
        // 'AccountProperty': {
        //     is_property: true,
        //     model: 'AccountProperty',
        //     alias: 'a_property',
        //     display: 'Account Additional Information',
        //     placeholder: '',
        //     required: false,
        //     admin: false,
        //     multiple: true,
        //     prefix: 'ap'
        // }
    },
    prefix: 'a',
    display: 'User Account'
}

export const userFields = {
    fields: {
        email: {
            alias: 'email',
            display: 'Email',
            type: 'text',
            placeholder: 'Please enter a valid email',
            regexPattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
            required: true,
            create: true,
            edit: true,
        },
        is_active: {
            alias: 'is_active',
            display: 'Is Active',
            type: 'boolean',
            placeholder: '',
            required: false,
            create: false,
            edit: true,
        },
        // is_staff: {
        //     alias: 'is_staff',
        //     display: 'Is admin account',
        //     type: 'boolean',
        //     placeholder: '',
        //     required: false,
        //     create: true,
        //     edit: true,
        // },
        first_name: {
            alias: 'first_name',
            display: 'First Name',
            type: 'text',
            placeholder: 'Less than 150 characters',
            regexPattern: '^.{0,150}$',
            required: false,
            create: true,
            edit: true,
        },
        last_name: {
            alias: 'last_name',
            display: 'Last Name',
            type: 'text',
            placeholder: 'Less than 150 characters',
            regexPattern: '^.{0,150}$',
            required: false,
            create: true,
            edit: true,
        },
        username: {
            alias: 'username',
            display: 'Username',
            type: 'text',
            placeholder: 'Between 3 and 150 characters',
            helperText: 'Username must be unique. Between 3 and 150 characters',
            regexPattern: '^[\\w.@+-]{3,150}$',
            required: true,
            create: true,
            edit: true,
        },
        password: {
            alias: 'password',
            display: 'Password',
            type: 'password',
            placeholder: '',
            helperText: 'At least 8 characters.\nMust contain 1 letter and 1 special character.\nCan not start with "!".',
            regexPattern: '^(?!\\!)(?=.*\\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,128}$',
            required: true,
            create: true,
            edit: false,
        }
    },
    relations: {},
    prefix: null,
    display: null
}

export const firmwareFields = {
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        f_name: {
            alias: 'f_name',
            display: 'Firmware Name',
            type: 'text',
            placeholder: 'Less than 64 characters',
            helperText: '',
            regexPattern: '^[\\w-_]{0,64}$',
            required: true,
            create: true,
            edit: true,
        },
        f_version: {
            alias: 'f_version',
            display: 'Version',
            type: 'text',
            placeholder: 'Less than 15 characters',
            helperText: 'only 0-9 and . allowed',
            regexPattern: '^[0-9.]{1,15}$\n',
            required: true,
            create: true,
            edit: true,
        },
        f_content: {
            alias: 'f_content',
            display: 'File Name',
            type: 'text',
            required: false,
            create: false,
            edit: false,
        },
        f_feature: {
            alias: 'f_feature',
            display: 'Features',
            type: 'text',
            placeholder: '',
            regexPattern: '',
            required: false,
            create: true,
            edit: true,
        },
        f_buildDate: {
            alias: 'f_buildDate',
            display: 'Built Date',
            type: 'date',
            placeholder: '',
            required: false,
            create: true,
            edit: true,
        },
        f_timeAdded: {
            alias: 'f_timeAdded',
            display: 'Time Added',
            type: 'datetime',
            create: false,
            edit: false
        },
        f_md5: {
            alias: 'f_md5',
            display: 'MD5',
            type: 'string',
            create: false,
            edit: false
        },
    },
    relations: {
        'FirmwareProperty': {
            is_property: true,
            model: 'FirmwareProperty',
            alias: 'f_property',
            display: 'Firmware Additional Information',
            placeholder: '',
            required: false,
            multiple: true,
            prefix: 'fp'
        }
    },
    prefix: 'f',
    display: 'Firmware'
}

export const aiModelFields = {
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        am_name: {
            alias: 'am_name',
            display: 'AI Model Name',
            type: 'text',
            placeholder: 'Less than 64 characters',
            helperText: '',
            regexPattern: '^[\\w-_]{0,64}$',
            required: true,
            create: true,
            edit: true,
        },
        am_content: {
            alias: 'am_content',
            display: 'File Name',
            type: 'text',
            required: false,
            create: false,
            edit: false,
        },
        am_version: {
            alias: 'am_version',
            display: 'Version',
            type: 'text',
            placeholder: 'Less than 15 characters',
            helperText: 'only 0-9 and . allowed',
            regexPattern: '^[0-9.]{1,15}$\n',
            required: true,
            create: true,
            edit: true,
        },
        am_feature: {
            alias: 'am_feature',
            display: 'Features',
            type: 'text',
            placeholder: '',
            regexPattern: '',
            required: false,
            create: true,
            edit: true,
        },
        am_buildDate: {
            alias: 'am_buildDate',
            display: 'Built Date',
            type: 'date',
            placeholder: '',
            required: false,
            create: true,
            edit: true,
        },
        am_timeAdded: {
            alias: 'am_timeAdded',
            display: 'Time Added',
            type: 'datetime',
            create: false,
            edit: false
        },
        am_md5: {
            alias: 'am_md5',
            display: 'MD5',
            type: 'string',
            create: false,
            edit: false
        },
    },
    relations: {
        'AIModelProperty': {
            is_property: true,
            model: 'AIModelProperty',
            alias: 'am_property',
            display: 'AI Model Additional Information',
            placeholder: '',
            required: false,
            multiple: true,
            prefix: 'amp'
        }
    },
    prefix: 'am',
    display: 'AI Model'
}

export const applicationFields = {
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        app_name: {
            alias: 'app_name',
            display: 'Application Name',
            type: 'text',
            placeholder: 'Less than 64 characters',
            helperText: '',
            regexPattern: '^[\\w-_]{0,64}$',
            required: true,
            create: true,
            edit: true,
        },
        app_content: {
            alias: 'app_content',
            display: 'File Name',
            type: 'text',
            required: false,
            create: false,
            edit: false,
        },
        app_version: {
            alias: 'app_version',
            display: 'Version',
            type: 'text',
            placeholder: 'Less than 15 characters',
            helperText: 'only 0-9 and . allowed',
            regexPattern: '^[0-9.]{1,15}$\n',
            required: true,
            create: true,
            edit: true,
        },
        app_feature: {
            alias: 'app_feature',
            display: 'Features',
            type: 'text',
            placeholder: '',
            regexPattern: '',
            required: false,
            create: true,
            edit: true,
        },
        app_buildDate: {
            alias: 'app_buildDate',
            display: 'Built Date',
            type: 'date',
            placeholder: '',
            required: false,
            create: true,
            edit: true,
        },
        app_timeAdded: {
            alias: 'app_timeAdded',
            display: 'Time Added',
            type: 'datetime',
            create: false,
            edit: false
        },
        app_md5: {
            alias: 'app_md5',
            display: 'MD5',
            type: 'string',
            create: false,
            edit: false
        },
    },
    relations: {
        'ApplicationProperty': {
            is_property: true,
            model: 'ApplicationProperty',
            alias: 'app_property',
            display: 'Application Additional Information',
            placeholder: '',
            required: false,
            multiple: true,
            prefix: 'apr'
        }
    },
    prefix: 'app',
    display: 'Application'
}

export const organizationFields = {
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        o_name: {
            alias: 'o_name',
            display: 'Organization Name',
            type: 'text',
            placeholder: '',
            helperText: '',
            regexPattern: '^[\\w-_]$',
            required: true,
            create: false,
            edit: true,
        },
        o_alias: {
            alias: 'o_alias',
            display: 'Organization Alias',
            type: 'text',
            required: true,
            create: false,
            edit: true,
        },
        o_description: {
            alias: 'o_description',
            display: 'Description',
            type: 'text',
            placeholder: '',
            helperText: '',
            regexPattern: '',
            multiline: true,
            required: true,
            create: false,
            edit: true,
        },
        o_usedStorage: {
            alias: 'o_usedStorage',
            display: 'Used Storage (GB)',
            type: 'float',
            placeholder: '',
            regexPattern: '',
            required: false,
            create: false,
            edit: false,
        },
        o_maxStorage: {
            alias: 'o_maxStorage',
            display: 'Total Storage (GB)',
            type: 'float',
            placeholder: '',
            required: false,
            create: false,
            edit: false,
        },
        o_billing: {
            alias: 'o_billing',
            display: 'Billing',
            type: 'string',
            multiple: true,
            create: false,
            edit: false
        },
        o_timeAdded: {
            alias: 'o_timeAdded',
            display: 'Time Added',
            type: 'datetime',
            create: false,
            edit: false
        },
    },
    relations: {},
    prefix: 'o',
    display: 'Organization'
}

export const alertFields={
    fields: {
        id: {
            alias: 'id',
            display: 'ID',
            type: 'number',
            create: false,
            edit: false
        },
        related_unit: {
            alias: 'related_unit',
            display: 'Related Unit SN',
            type: 'text',
            create: false,
            edit: false
        },
        related_camera: {
            alias: 'related_camera',
            display: 'Related Camera SN',
            type: 'text',
            create: false,
            edit: false
        },
        al_severity: {
            alias: 'al_severity',
            display: 'Severity',
            type: 'enum',
            create: false,
            edit: false
        },
        al_description: {
            alias: 'al_description',
            display: 'Description',
            type: 'text',
            create: false,
            edit: false
        },
        al_status: {
            alias: 'al_status',
            display: 'Status',
            type: 'enum',
            create: false,
            edit: false
        },
        al_timeAdded: {
            alias: 'al_timeAdded',
            display: 'Time Added',
            type: 'datetime',
            create: false,
            edit: false
        },
        al_timeDismissed: {
            alias: 'al_timeDismissed',
            display: 'Time Dismissed',
            type: 'datetime',
            create: false,
            edit: false
        },
        al_dismisser: {
            alias: 'al_dismisser',
            display: 'Dismisser',
            type: 'number',
            create: false,
            edit: false
        },
        al_tag: {
            alias: 'al_tag',
            display: 'Tags',
            type: 'list',
            create: false,
            edit: false
        },
        al_notes: {
            alias: 'al_notes',
            display: 'Dismissal Notes',
            type: 'text',
            create: false,
            edit: false
        },
    },
    relations: {},
    prefix: 'al',
    display: 'Alert'
}