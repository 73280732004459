import {Grid, InputAdornment, MenuItem, OutlinedInput, Select} from "@mui/material";
import Button from "@mui/material/Button";
import SearchIcon from '@mui/icons-material/Search';
import React, {useContext, useEffect, useState} from "react";
import {CheckOutlined, CloseOutlined} from "@mui/icons-material";
import {boolSearchFields, dateSearchFields, enumSearchFields} from "../util/searchFields";
import {EnumContext} from "./enumContext";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function SearchBox({prefix, searchFields, searchParams, setSearchParams, sendSearch, sx}) {
    const [enableSearch, setEnableSearch] = useState(false);
    const {enums} = useContext(EnumContext);
    const handleChange = (e) => {
        if (!e.target.value) {
            const tempParams = {...searchParams};
            delete tempParams[e.target.name];
            setSearchParams(tempParams);
        } else {
            setSearchParams({
                ...searchParams,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleEditDate = (key, value) => {
        if (!value) {
            const tempParams = {...searchParams};
            delete tempParams[key];
            setSearchParams(tempParams);
        } else {
            setSearchParams({
                ...searchParams,
                [key]: value.format('YYYY-MM-DD')
            })
        }
    }

    useEffect(() => {
        setEnableSearch(Object.values(searchParams).reduce((acc, value) => (acc && value), Object.values(searchParams).length > 0));
    }, [searchParams]);

    return <>
        <Grid container
              spacing={2}
              sx={sx}
        >
            {Object.entries(searchFields).map(([key, value]) => (
                <Grid item xs={12} md={6} lg={4} key={`${key}-field`}>
                    {boolSearchFields.includes(key) ?
                        <Select
                            fullWidth
                            input={
                                <OutlinedInput
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span style={{color: '#404040'}}>{value}: </span>
                                        </InputAdornment>
                                    }
                                />
                            }
                            name={key}
                            key={key}
                            onChange={handleChange}
                            value={searchParams[key] || ''}
                            sx={{height: 35}}
                        >
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value='true' key='true'><CheckOutlined/></MenuItem>
                            <MenuItem value='false' key='false'><CloseOutlined/></MenuItem>
                        </Select> :
                        (enumSearchFields.includes(key) ?
                                <Select
                                    fullWidth
                                    input={
                                        <OutlinedInput
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <span style={{color: '#404040'}}>{value}: </span>
                                                </InputAdornment>
                                            }
                                        />
                                    }
                                    name={key}
                                    key={key}
                                    onChange={handleChange}
                                    value={searchParams[key] || ''}
                                    sx={{height: 35}}
                                >
                                    <MenuItem value='' key=''>
                                        <em>None</em>
                                    </MenuItem>
                                    {Object.entries(enums[key].data).map(([k, v]) => (
                                        <MenuItem value={k} key={k}>{v}</MenuItem>
                                    ))}
                                </Select> :
                                (dateSearchFields.includes(key) ?
                                        <LocalizationProvider dateAdapter={AdapterDayjs} key={'date-select'}>
                                            <DatePicker
                                                id={key}
                                                format='YYYY-MM-DD'
                                                name={key}
                                                value={searchParams[key] ? dayjs(searchParams[key]) : null}
                                                onChange={(value) => handleEditDate(key, value)}
                                                slotProps={{
                                                    textField:
                                                        {
                                                            fullWidth: true,
                                                            sx: {
                                                                '& .MuiInputBase-root': {
                                                                    height: 35,
                                                                    backgroundColor: '#f9f9f9',
                                                                },
                                                            },
                                                            InputProps: {
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <span
                                                                            style={{color: '#404040'}}>{value}: </span>
                                                                    </InputAdornment>
                                                                ),
                                                            },
                                                        },
                                                }}
                                                sx={{height: 35}}
                                            />
                                        </LocalizationProvider> :
                                        <OutlinedInput
                                            fullWidth
                                            key={key}
                                            name={key}
                                            type={(key === `${prefix}_version`) ? 'number' : 'text'}
                                            onChange={handleChange}
                                            startAdornment={<InputAdornment position="start">
                                                <span style={{color: '#404040'}}>{value}: </span>
                                            </InputAdornment>}
                                            value={searchParams[key] || ''}
                                            sx={{height: 35}}
                                        />
                                )
                        )
                    }
                </Grid>
            ))}
            <Grid item xs={12} sx={{mt: 1}} key='submit'>
                <Button onClick={sendSearch}
                        variant='contained'
                        color='search'
                        startIcon={<SearchIcon/>}
                        sx={{color: 'white'}}
                        disabled={!enableSearch}
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    </>
}