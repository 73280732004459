import Cookies from "js-cookie";
import {fileUrl, routes, useGetDataById, useGetDataList, useRefreshData} from "../routes/api";
import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {LoadingComponent} from "../util/loading";
import {
    Alert,
    AlertTitle,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    Grid,
    IconButton
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    flattenRecord,
    generateImageName,
    OverflowTooltip,
    sleep,
    toHumanReadableTime,
    updateConfirmationMessage,
    updateData,
} from "../util/util";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';
import HideImageIcon from '@mui/icons-material/HideImage';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
// import {ImagePopUpModal} from "../components/image";
import EditIcon from "@mui/icons-material/Edit";
import {AIModelRelationHeaders, CameraHeaders} from "../util/getFields";
import ContentDetail from "../components/contentDetail";
import PopupDialog from "../components/popupDialog";
import RelationEdit from "../components/relationEdit";
import {aiModelFields, cameraFields} from "../util/modelFields";
import {DeleteComponent} from "../components/confirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import NavigateBreadcrumbs from "../components/navBreadcrumbs";
import ErrorDisplay from "../components/errorDisplay";
import {grey} from "@mui/material/colors";
import StatusList from "../components/statusList";
import WaitTimeStatus from "../components/waitTimeStatus";
import {EnumContext} from "../components/enumContext";
import {RenderChart} from "../components/chart";
import ExternalAIModelDialog from "../components/externalAIModel";
import FilterIcon from '@mui/icons-material/Filter';
import DownloadIcon from '@mui/icons-material/Download';


export default function CameraDetail() {
    const token = Cookies.get('token');
    const permissions = Cookies.get('permissions') ?
        JSON.parse(Cookies.get('permissions')).unit :
        {create: false, edit: false, delete: false, view: false};
    const webAdmin = Cookies.get('webAdmin') === 'T';
    const {id} = useParams();
    const route = routes.camera;
    const recordRoute = routes.record;
    const {enums} = useContext(EnumContext);

    const [snackbarList, setSnackbarList] = useState([]);
    const [newData, setNewData] = useState({});
    const [records, setRecords] = useState([]);
    const [noRecord, setNoRecord] = useState(true);
    const [noImage, setNoImage] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [refreshError, setRefreshError] = useState(false);
    const [deleteError, setDeleteError] = useState({error: false, message: null, status: null});
    const [modalOpen, setModalOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [aiModelOpen, setAIModelOpen] = useState(false);
    const [popup, setPopup] = useState({
        'aimodel': false,
        'cameraproperty': false
    })

    // const [autoRefresh, setAutoRefresh] = useState(sessionStorage.getItem('refresh') === 'T');
    const [autoRefresh, setAutoRefresh] = useState(false);

    const {data: detail, loading, statusCode: getStatus, handleCall} = useGetDataById(token, route, id);
    const {
        data: recordDetail,
        loading: recordLoading,
        statusCode: recordStatus,
        handleCall: recordCall
    } = useGetDataList(token, recordRoute);
    const {statusCode: refreshStatus, handleCall: refreshCall} = useRefreshData(token, route, id);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from ? location.state.from : {pathname: `/`};

    const unitId = location.state?.unitId ? location.state.unitId : null;
    const unitSN = location.state?.unitSN ? location.state.unitSN : null;
    const page = location.state?.page ? location.state.page : null;
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        if (getStatus > 400) {
            // navigate('/camera');
            navigate('/unit');
        }
    }, [getStatus]);

    const addSnackbar = (field, model, time) => {
        const id = new Date().getTime();
        setSnackbarList((prevSnackbars) => [
            ...prevSnackbars,
            {id, field, model, time},
        ]);
    };

    const handleClose = (id, event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarList((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.id !== id));
    };

    const handleAIModelClick = (aimodel) => {
        navigate(`/aimodel/${aimodel}`, {
            state: {
                from: location,
                unitSN: unitSN,
                unitId: unitId,
                cameraSN: newData.data.c_sn,
                cameraId: id,
                page: page
            }
        });
    }

    const handleModal = () => {
        setModalOpen((m) => !m);
    }

    const handleDownloadImage = () => {
        const link = document.createElement("a");
        link.href = imageSrc;
        link.download = generateImageName(newData.data.c_sn, newData.image.time);
        link.type = newData.image.mimetype;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleRefresh = async () => {
        try {
            setRefreshing(true);
            await refreshCall({body: {type: 'IMG'}});
            if (refreshStatus < 300) {
                await sleep(4000);
                addSnackbar('Refreshing', 'Camera', '2 minutes')
                await handleCall();
            } else {
                setRefreshError(true);
            }
            setRefreshing(false);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        let interval;
        if (autoRefresh) {
            fetchDetail();
            fetchRecordDetail();
            interval = setInterval(() => {
                fetchDetail();
                fetchRecordDetail();
            }, 120000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [autoRefresh]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         handleRefresh();
    //     }, 1800000);
    //
    //     return () => clearInterval(interval);
    // }, []);

    const fetchDetail = async () => {
        try {
            await handleCall();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchRecordDetail = async () => {
        const today = new Date();
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getUTCDate() - 7);
        const formattedDate = oneWeekAgo.toISOString().split('T')[0];
        try {
            await recordCall({params: {r_timeAdded_g: formattedDate, r_camera: id, np: 'T'}});
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    }

    const handlePopupClose = (key) => {
        setPopup({...popup, [key]: false});
    }

    const handleSetAutoRefresh = (e) => {
        e.preventDefault();
        setAutoRefresh(e.target.checked);
        sessionStorage.setItem('refresh', e.target.checked ? 'T' : 'F')
    }

    useLayoutEffect(() => {
        if (!(permissions.view || webAdmin)) {
            navigate(from);
        } else {
            fetchDetail();
            fetchRecordDetail();
        }
    }, []);

    useEffect(() => {
        if (JSON.stringify(detail) !== '{}') {
            // && filterTime(record, timeDiff)
            const updatedData = updateData(detail.data, CameraHeaders, 'c', enums);
            const updatedAIModel = updateData(detail.AIModel, AIModelRelationHeaders, 'am',);
            let updatedConfirmationMessage = updateConfirmationMessage(detail.ConfirmationMessage);
            setNewData({
                ...detail,
                data: updatedData,
                AIModel: updatedAIModel,
                ConfirmationMessage: updatedConfirmationMessage,
            });
            (detail.image && detail.image.file) ? setNoImage(false) : setNoImage(true);
        }
    }, [detail]);

    useEffect(() => {
        if (JSON.stringify(recordDetail) !== '{}') {
            const updatedRecords = (recordDetail && recordDetail.length > 0) ? recordDetail.filter((record) => (record.time)).sort((a, b) => new Date(a.time) - new Date(b.time)).map((record) => flattenRecord(record)) : [];
            setRecords(updatedRecords);
            (updatedRecords && updatedRecords.length > 0) ? setNoRecord(false) : setNoRecord(true);
        }
    }, [recordDetail]);

    useEffect(() => {
        if (!newData?.image?.file) return;
        const fetchImage = async () => {
            const response = await fetch(fileUrl(newData.image.file), {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (response.ok) {
                const blob = await response.blob();
                const objectUrl = URL.createObjectURL(blob);
                setImageSrc(objectUrl);
            } else {
                setNoImage(true);
            }
        };

        fetchImage();

        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [newData]);

    if (loading || recordLoading || Object.keys(newData).length === 0) return <LoadingComponent/>

    return <Grid container spacing={2} alignItems="stretch" justifyContent="center"
                 sx={{margin: 'auto', mt: 5, width: '80%'}}>
        <Grid item xs={12}>
            <Collapse in={refreshError}>
                <Alert severity="error"
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setRefreshError(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                       sx={{mb: 2}}
                >
                    <AlertTitle>Error</AlertTitle>
                    Couldn't refresh at the time. Please try again later.
                </Alert>
            </Collapse>
            <ErrorDisplay
                fields={cameraFields}
                alert={deleteError.error}
                setAlert={(alert) => setDeleteError({...deleteError, error: alert})}
                response={deleteError.message}
                statusCode={deleteError.status}
            />
            <WaitTimeStatus
                snackbarList={snackbarList}
                handleClose={handleClose}
            />
        </Grid>
        <Grid item xs={12}>
            <NavigateBreadcrumbs
                alias='camera'
                display='Camera'
                id={newData.data.c_sn}
                unitId={unitId}
                unitSN={unitSN}/>
        </Grid>
        <Grid item xs={12} sx={{mb: 3}}>
            <Typography variant='h4'>Camera {newData.data.c_sn}</Typography>
        </Grid>
        <Grid item xs={4} sm={3} lg={2}>
            {(permissions.delete || webAdmin) && <DeleteComponent
                alias='camera'
                id={id}
                navigate={navigate}
                setError={setDeleteError}
            />}
        </Grid>
        <Grid item xs={8} sm={9} lg={10} key="refresh" sx={{display: "flex", justifyContent: "flex-end"}}>
            <Button variant="contained" onClick={handleRefresh} startIcon={<RefreshIcon/>}>Refresh</Button>
        </Grid>
        <Grid item xs={12} key="external-ai-model" sx={{mb: 5, display: "flex", justifyContent: "flex-end"}}>
            {newData.data.c_sensor ?
                <Button variant="contained" onClick={() => setAIModelOpen(true)} startIcon={<FilterIcon/>}>
                    Update External AI Model
                </Button> :
                <Tooltip title='Sensor Serial Number required to use external AI Model'>
                    <span>
                        <Button variant="contained" startIcon={<FilterIcon/>} disabled>Update External AI Model</Button>
                    </span>
                </Tooltip>
            }
        </Grid>
        {refreshing &&
            [
                <Grid item xs={5}/>,
                <Grid item xs={2}>
                    <CircularProgress/>
                </Grid>,
                <Grid item xs={5}/>
            ]
        }
        <ExternalAIModelDialog
            dialogOpen={aiModelOpen}
            handleDialogClose={() => setAIModelOpen(false)}
            sensorID={newData.data.c_sensor}
        />

        <Grid item xs={12} key="camera-detail" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={(permissions.edit || webAdmin) ?
                        <IconButton onClick={() => setEdit(!edit)}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='Details'
                />
                <CardContent>
                    <ContentDetail
                        alias='camera'
                        id={id}
                        data={newData.data}
                        edit={edit}
                        setEdit={setEdit}
                        refresh={fetchDetail}
                        fields={cameraFields}
                    />
                    <StatusList data={newData.ConfirmationMessage}/>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} key="properties" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={(permissions.edit || webAdmin) ?
                        <IconButton onClick={() => (setPopup({...popup, cameraproperty: true}))}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title="Additional Information"/>
                {newData.CameraProperty.length === 0 ?
                    <CardContent>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No additional information
                        </Typography>
                    </CardContent> :
                    <CardContent>
                        <Grid container
                              spacing={1}
                              sx={{margin: 'auto', mb: 2}}
                              alignItems="center"
                              justifyContent="center"
                        >
                            {newData.CameraProperty.map(([, [key, value, desc]]) => (
                                [
                                    <Grid item xs={5} key={key} sx={{mb: 1}}>
                                        <Tooltip title={desc} arrow>
                                            <Typography variant="body1" color={grey[700]}>
                                                {key}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>,
                                    <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                        <Tooltip title={desc} arrow placement="bottom-start">
                                            <Typography variant="body1">
                                                {value}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                ]
                            ))}
                        </Grid>
                    </CardContent>}
            </Card>
        </Grid>
        <Grid item xs={12} lg={6} key="camera-model" sx={{display: 'flex'}}>
            <Card sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    action={webAdmin ?
                        <IconButton onClick={() => (setPopup({...popup, aimodel: true}))}>
                            <EditIcon/>
                        </IconButton> :
                        null
                    }
                    title='AI Model'
                />
                {Object.keys(newData.AIModel).length === 0 ?
                    <CardContent>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No Related AI Model
                        </Typography>
                    </CardContent> :
                    <CardContent>
                        <CardActionArea onClick={webAdmin ? () => (handleAIModelClick(detail.AIModel.id)) : null}>
                            <Grid container
                                  spacing={1}
                                  sx={{margin: 'auto', mb: 2}}
                                  alignItems="center"
                                  justifyContent="center"
                            >
                                {Object.entries(newData.AIModel).filter((item) => (item[0] !== 'id')).map(([key, value]) => (
                                    [
                                        <Grid item xs={5} key={key} sx={{mb: 1}}>
                                            <Typography variant="body1" color={grey[700]}>
                                                {aiModelFields.fields[key].display || key}
                                            </Typography>
                                        </Grid>,
                                        <Grid item xs={7} key={`${key}-value`} sx={{mb: 1}}>
                                            <OverflowTooltip followCursor>
                                                <Typography variant="body1">
                                                    {value}
                                                </Typography>
                                            </OverflowTooltip>
                                        </Grid>
                                    ]
                                ))}
                            </Grid>
                        </CardActionArea>
                    </CardContent>
                }
            </Card>
        </Grid>
        <PopupDialog
            open={popup.cameraproperty}
            content={<RelationEdit
                fields={cameraFields}
                alias='camera'
                display='Camera'
                id={id}
                relation='CameraProperty'
                refresh={fetchDetail}
                addSnackbar={addSnackbar}
                defaultValue={newData.CameraProperty.map(([id, [key, value]]) => ([id, `${key}: ${value}`]))}
                setEdit={(value) => (setPopup({...popup, cameraproperty: value}))}
            />}
            handleClose={() => handlePopupClose('cameraproperty')}
        />
        <PopupDialog
            key='model-popup'
            open={popup.aimodel}
            content={<RelationEdit
                fields={cameraFields}
                alias='camera'
                display='Camera'
                id={id}
                relation='AIModel'
                refresh={fetchDetail}
                addSnackbar={addSnackbar}
                defaultValue={
                    Object.keys(newData.AIModel).length === 0 ?
                        null :
                        [newData.AIModel.id, `${newData.AIModel.am_name} (${newData.AIModel.am_version})`]
                }
                setEdit={(value) => (setPopup({...popup, aimodel: value}))}
            />}
            handleClose={() => handlePopupClose('aimodel')}
        />
        <Grid item xs={12} lg={6} key="image">
            {noImage ?
                <Card key='no-image-card'
                      sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                    <CardHeader title="Recent Image"/>
                    <CardContent>
                        <IconButton disableRipple>
                            <HideImageIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            Image not Available
                        </Typography>
                    </CardContent>
                </Card>
                : [
                    <Card key='image-card'
                          sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                        <CardHeader
                            title="Recent Image"
                            action={
                                <IconButton onClick={handleDownloadImage}>
                                    <DownloadIcon/>
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Grid container
                                  spacing={1}
                                  sx={{margin: 'auto'}}
                                  alignItems="center"
                                  justifyContent="center"
                            >
                                <Grid item xs={3} key='refresh-key'>
                                    <Typography variant="body1" color={grey[700]}>
                                        Time Taken
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} key='refresh-value'>
                                    <Typography variant="body1">
                                        {newData.image ? toHumanReadableTime(newData.image.time).datetimeStr : "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        {newData.url ?
                            <CardActionArea
                                component="a"
                                href={newData.c_order === '1' ? newData.url + ':7080' : newData.url + ':8080'}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{mb: 2}}>
                                <CardMedia
                                    component="img"
                                    image={imageSrc}
                                    alt={newData.image.time}
                                />
                            </CardActionArea> :
                            <CardMedia
                                component="img"
                                image={imageSrc}
                                alt={newData.image.time}
                            />
                        }
                    </Card>
                    // <ImagePopUpModal
                    //     key='image-popup'
                    //     img={imageSrc}
                    //     open={modalOpen}
                    //     setOpen={setModalOpen}
                    //     currentPoints={detail.data.c_coordinates}
                    //     alt={newData.image.time}
                    //     id={id}
                    //     addSnackbar={addSnackbar}
                    //     refresh={fetchDetail}
                    // />
                ]
            }
        </Grid>
        <Grid item sm={12} key="chart" sx={{mt: 2, minHeight: 400}}>
            <Card key='chart-card'
                  sx={{borderRadius: '16px', width: '100%', height: '100%', padding: 2, paddingBottom: 0}}>
                <CardHeader
                    title="Data Visualization"
                    // action={<FormControlLabel
                    //     control={<Switch
                    //         color="success"
                    //     />}
                    //     checked={autoRefresh}
                    //     onChange={handleSetAutoRefresh}
                    //     label="Auto Refresh"
                    //     labelPlacement="start"
                    //     sx={{margin: 0}}
                    // />}
                />
                <CardContent>
                    {noRecord ? <Typography variant="button">
                            No Data Found
                        </Typography>
                        : <RenderChart data={records}/>}
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}