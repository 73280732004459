import {Autocomplete, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import React, {useEffect, useLayoutEffect, useState} from "react";
import Cookies from "js-cookie";
import {routes, useGetDataList, usePostData} from "../routes/api";
import Button from "@mui/material/Button";
import ArticleIcon from '@mui/icons-material/Article';
import Box from "@mui/material/Box";

export default function ExternalAIModelDialog({dialogOpen, handleDialogClose, sensorID}) {
    const token = Cookies.get('token');
    const route = routes.aimodelexternal;

    const [open, setOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState('');
    const [fetchedID, setFetchedID] = useState([]);

    const {
        data: modelData,
        loading: getLoading,
        statusCode: getStatus,
        handleCall: getModelList
    } = useGetDataList(token, route);
    const {
        data: postData,
        loading: postLoading,
        statusCode: postStatus,
        handleCall: postExternalModel
    } = usePostData(token, route);

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                await getModelList({});
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (JSON.stringify(modelData) !== '{}') {
            setFetchedID(modelData.map((item) => ([item.id, item.name])));
        }
    }, [modelData, getStatus]);

    const handleChange = (newValue) => {
        if (!newValue) {
            setSelectedModel('');
        } else {
            setSelectedModel(newValue);
        }
    }

    const handleSubmit = async () => {
        await postExternalModel({body: {model_id: selectedModel[0], sensor_ids: [sensorID]}});
        handleDialogClose();
    }

    if (getLoading) {
        return <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="popup-dialog-title"
            aria-describedby="popup-dialog-description"
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle>
                Select External AI Model
            </DialogTitle>
            <DialogContent
                sx={{
                    minWidth: '270px',
                    minHeight: '110px',
                    mt: 2,
                    overflow: 'visible'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 5,
                        mb: 5
                    }}
                >
                    <CircularProgress/>
                </Box>
            </DialogContent>
        </Dialog>
    }

    if (fetchedID.length === 0 || !sensorID) {
        return <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="popup-dialog-title"
            aria-describedby="popup-dialog-description"
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle>
                Select External AI Model
            </DialogTitle>
            <DialogContent
                sx={{
                    minWidth: '270px',
                    minHeight: '110px',
                    mt: 2,
                    overflow: 'visible'
                }}
            >
                <Typography variant="subtitle1" color="grey"
                            sx={{fontSize: '1.1rem', display: 'flex', alignItems: 'center'}}>
                    <IconButton disableRipple>
                        <ArticleIcon sx={{fontSize: 30}}/>
                    </IconButton>
                    No Available AI Model
                </Typography>
            </DialogContent>
        </Dialog>
    }

    return <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="popup-dialog-title"
        aria-describedby="popup-dialog-description"
        fullWidth
        maxWidth='xs'
    >
        <DialogTitle>
            Select External AI Model
        </DialogTitle>
        <DialogContent
            sx={{
                minWidth: '270px',
                minHeight: '110px',
                mt: 2,
                overflow: 'visible'
            }}
        >
            <Autocomplete
                id='external-ai-model'
                open={open}
                onOpen={() => (setOpen(true))}
                onClose={() => (setOpen(false))}
                label='External AI Model'
                name='external-ai-model'
                isOptionEqualToValue={(option, v) => option[0] === v[0]}
                getOptionLabel={(option) => option[1]}
                value={selectedModel || null}
                options={fetchedID}
                onChange={(e, newValue) => {
                    handleChange(newValue);
                }}
                loading={getLoading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label='External AI Model'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {getLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <Button component="label"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!selectedModel}
                    sx={{margin: 1, mt: 3}}>
                Submit
            </Button>
        </DialogContent>
    </Dialog>
}