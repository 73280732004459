import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {Alert, AlertTitle, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import ASLogo from "../assets/logo/as_logo_small.png";
import Typography from "@mui/material/Typography";
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import Button from "@mui/material/Button";
import {useResetPasswordRequest} from "../routes/api";
import {userFields} from "../util/modelFields";
import {LoadingComponent} from "../util/loading";

export default function ForgotPassword() {
    const emailRegexp = new RegExp(userFields.fields.email.regexPattern);
    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState(false);
    const [status, setStatus] = useState('success');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [sentEmail, setSentEmail] = useState('');
    const [count, setCount] = useState(0);

    const [secondsLeft, setSecondsLeft] = useState(0 || Number(sessionStorage.getItem('passwordSecondsLeft')));
    const [disabled, setDisabled] = useState(false);

    const {data, statusCode, handleCall} = useResetPasswordRequest();

    const handleChange = (e) => {
        setEmail(e.target.value);
        setDisabled(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await handleCall({body: {email: email}});
        setCount((c) => c + 1);
        setLoading(false);
    }

    useEffect(() => {
        if (email === sentEmail && secondsLeft > 0) {
            setDisabled(true);
        }
    }, [email]);

    useEffect(() => {
        if (count === 0) {
            return;
        }
        if (statusCode >= 200 && statusCode < 300) {
            setStatus('success');
            setMessage('We have sent you a link to reset your password if you have an account associated with the email you provided. If it doesn’t show up within a few minutes, check your spam folder.')
            setAlert(true);
            setDisabled(true);
            setSecondsLeft(59);
            setSentEmail(email);
            sessionStorage.setItem('passwordSecondsLeft', '59');
        } else if (statusCode === 403) {
            setStatus('error');
            setMessage(String(data));
            setAlert(true);
            setDisabled(true);
        } else if (statusCode >= 400) {
            setStatus('error');
            setMessage(String(data));
            setAlert(true);
        }
    }, [data, statusCode, count]);

    useEffect(() => {
        let intervalId;

        if (secondsLeft > 0) {
            intervalId = setInterval(() => {
                setSecondsLeft((prevSeconds) => prevSeconds - 1);
                sessionStorage.setItem('passwordSecondsLeft', String(secondsLeft));
            }, 1000);
        } else if (secondsLeft === 0 && disabled) {
            setDisabled(false);
        }

        return () => clearInterval(intervalId);
    }, [secondsLeft, disabled]);

    if (loading) return <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Collapse in={alert}>
            <Alert severity={status === 'success' ? 'success' : 'error'}
                   action={
                       <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                               setAlert(false);
                           }}
                       >
                           <CloseIcon fontSize="inherit"/>
                       </IconButton>
                   }
                   sx={{mb: 2}}
            >
                <AlertTitle>{status === 'success' ? 'Thank you' : 'Error'}</AlertTitle>
                {message}
            </Alert>
        </Collapse>

        <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <img src={ASLogo} alt='Aglaia Sense' style={{width: '60%'}}/>
            <Typography component="h1" variant="h5" color="#323232" sx={{mb: 3, mt: 1, fontWeight: 'bold'}}>
                Device Manager
            </Typography>
            <LockResetOutlinedIcon sx={{color: 'primary.light', m: 1, fontSize: 40}}/>
            <Typography component="h2" variant="h6" color="textPrimary">
                Forgot Password
            </Typography>
            <LoadingComponent/>
        </Box>
    </Container>

    return <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Collapse in={alert}>
            <Alert severity={status === 'success' ? 'success' : 'error'}
                   action={
                       <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                               setAlert(false);
                           }}
                       >
                           <CloseIcon fontSize="inherit"/>
                       </IconButton>
                   }
                   sx={{mb: 2}}
            >
                <AlertTitle>{status === 'success' ? 'Thank you' : 'Error'}</AlertTitle>
                {message}
            </Alert>
        </Collapse>

        <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <img src={ASLogo} alt='Aglaia Sense' style={{width: '60%'}}/>
            <Typography component="h1" variant="h5" color="#323232" sx={{mb: 3, mt: 1, fontWeight: 'bold'}}>
                Device Manager
            </Typography>
            <LockResetOutlinedIcon sx={{color: 'primary.light', m: 1, fontSize: 40}}/>
            <Typography component="h2" variant="h6" color="textPrimary">
                Forgot Password
            </Typography>
            <Box component="form"
                 onSubmit={handleSubmit}
                 noValidate
                 sx={{
                     mt: 1,
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     minWidth: '400px'
                 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={!!email && !emailRegexp.test(email)}
                    value={email}
                    helperText={(!!email && !emailRegexp.test(email)) && 'please enter a valid email'}
                    onChange={handleChange}
                    autoFocus
                />
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!email || disabled || !emailRegexp.test(email)}
                    sx={{mt: 3, mb: 2}}
                >
                    {disabled ? `0:${secondsLeft.toString().padStart(2, '0')} before next attempt` : 'Send reset email'}
                </Button>
            </Box>
        </Box>
    </Container>
}