import Cookies from "js-cookie";
import {routes, useBulkDismissData, useGetDataList, usePartialPatch} from "../routes/api";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import {
    Alert,
    AlertTitle,
    Backdrop,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    List,
    ListItem
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {EnumContext} from "./enumContext";
import CloseIcon from "@mui/icons-material/Close";
import {sleep, updateAlert} from "../util/util";
import {AddNotes, AlertConfirmation} from "./confirmationDialog";
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SearchBox from "./searchBox";
import {alertSearchFields} from "../util/searchFields";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';

export default function AlertBoard() {
    const token = Cookies.get('token');
    const permissions = Cookies.get('permissions') ?
        JSON.parse(Cookies.get('permissions')).unit :
        {create: false, edit: false, delete: false, view: false};
    const webAdmin = Cookies.get('webAdmin') === 'T';
    const {enums} = useContext(EnumContext);
    const alertRoute = routes.alert;

    const [alerts, setAlerts] = useState([]);
    const [filteredAlerts, setFilteredAlerts] = useState([]);
    const [closedAlerts, setClosedAlerts] = useState([]);
    const [batchDismissAlert, setBatchDismissAlert] = useState([]);
    const [patchLoading, setPatchLoading] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [error, setError] = useState({});
    const [allSelected, setAllSelected] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [notesOpen, setNotesOpen] = useState(false);
    const [singleNotesOpen, setSingleNotesOpen] = useState(false);
    const [notesContent, setNotesContent] = useState('');

    const [searchParams, setSearchParams] = useState({});
    const [searching, setSearching] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const searchRef = useRef(searching);

    const {
        data: bulkResp,
        loading: bulkCallLoading,
        statusCode: bulkStatus,
        handleCall: bulkCall
    } = useBulkDismissData(token, alertRoute);

    const {
        data: alertData,
        loading: alertLoading,
        statusCode: alertStatus,
        handleCall: alertCall
    } = useGetDataList(token, alertRoute);

    const {
        data: patchData,
        loading: patchCallLoading,
        statusCode: patchStatus,
        handleCall: patchCall
    } = usePartialPatch(token, alertRoute);

    const navigate = useNavigate();
    const location = useLocation();

    const severityToSeverity = (sev) => {
        const sevMap = {
            'Critical': 'error',
            'Moderate': 'warning',
            'Minor': 'warning'
        }
        return sevMap[sev]
    }

    const fetchAlertDetail = async () => {
        try {
            await alertCall({params: {np: 'T', al_status: 'NEW'}});
        } catch (error) {
            console.error('Error fetching alerts:', error);
        }
    }

    const fetchAlertSearchDetail = async () => {
        try {
            await alertCall({params: {...searchParams, np: 'T', al_status: 'NEW'}})
        } catch (error) {
            console.error('Error fetching searching alerts:', error);
        }
    }

    useEffect(() => {
        searchRef.current = searching;
        if (searching) {
            return; //DO NOT DELETE; this makes the code work
        }
    }, [searching]);

    useEffect(() => {
        let interval;
        if (searchRef.current) {
            fetchAlertSearchDetail();
        } else {
            fetchAlertDetail();
        }
        interval = setInterval(() => {
            if (searchRef.current) {
                fetchAlertSearchDetail();
            } else {
                fetchAlertDetail();
            }
        }, 10000);

        return () => {
            if (interval) clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (JSON.stringify(alertData) !== '{}') {
            if (searchRef.current) {
                setFilteredAlerts(alertData.map((alert) => updateAlert(alert, enums)));
            } else {
                setAlerts(alertData.map((alert) => updateAlert(alert, enums)));
                setClosedAlerts([]);
            }
        }
    }, [alertData]);

    const dismissSingleAlert = (id) => {
        setCurrentId(id);
        setSingleNotesOpen(true);
    }

    const handleConfirmDismissSingle = async () => {
        setSingleNotesOpen(false);
        setPatchLoading(true);
        await sleep(600);
        if (notesContent) {
            await patchCall({id: currentId, body: {al_status: 'DSM', al_notes: notesContent}});
        } else {
            await patchCall({id: currentId, body: {al_status: 'DSM'}});
        }
    }

    const handleCancel = () => {
        setNotesOpen(false);
        setOpenDialog(false);
    }

    const handleSingleCancel = () => {
        setCurrentId(-1);
        setSingleNotesOpen(false);
    }

    useEffect(() => {
        if (patchCallLoading) {
            return;
        }
        if (patchStatus < 200) {
            setNotesContent('');
            return;
        }
        if (patchStatus >= 200 && patchStatus < 300) {
            setBatchDismissAlert((prevState) => (prevState.filter((item) => item !== currentId)));
            setClosedAlerts((prevState) => [...prevState, currentId]);
        }
        setPatchLoading(false);

    }, [patchData, patchStatus, patchCallLoading]);

    const handleDismissCheck = (e, id) => {
        setBatchDismissAlert((prevState) => (e.target.checked ? [...prevState, id] : prevState.filter((item) => item !== id)));
    }

    const handleSelectAll = () => {
        if (allSelected) {
            setBatchDismissAlert([]);
            setAllSelected(false);
        } else {
            const allIds = alerts.map((alert) => alert.id).filter((id) => !closedAlerts.includes(id));
            setBatchDismissAlert(allIds);
            setAllSelected(true);
        }
    }

    const handleBatchDismiss = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleConfirmDismiss = async () => {
        setPatchLoading(true);
        setOpenDialog(false);
        setNotesOpen(false);
        await sleep(600);
        if (notesContent) {
            await bulkCall({body: {ids: batchDismissAlert, al_notes: notesContent}});
        } else {
            await bulkCall({body: {ids: batchDismissAlert}});
        }
    };

    useEffect(() => {
        if (bulkCallLoading) {
            return;
        }
        if (bulkStatus >= 200 && bulkStatus < 300) {
            setError({error: false, message: '', statusCode: bulkStatus});
            setNotesContent('');
            if (allSelected) {
                setBatchDismissAlert([]);
                setClosedAlerts([]);
                setAlerts([]);
            } else {
                const ids = batchDismissAlert;
                setClosedAlerts((prevState) => [...prevState, ...ids]);
                setBatchDismissAlert([]);
            }
        } else if (bulkStatus > 300) {
            setError({error: true, message: bulkResp, status: bulkStatus});
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        setPatchLoading(false);
    }, [bulkResp, bulkStatus, bulkCallLoading]);

    const handleOpenSearch = () => {
        setOpenSearch(true);
    }

    const handleCancelSearch = () => {
        setOpenSearch(false);
        setSearching(false);
        setSearchParams({});
        setFilteredAlerts([]);
        setBatchDismissAlert([]);
    }

    const handleSearchParams = (params) => {
        setSearchParams(params);
    }

    const handleSearch = async () => {
        setFilteredAlerts([]);
        setBatchDismissAlert([]);
        setSearching(true);
        await fetchAlertSearchDetail();
    }

    const handleNavigate = () => {
        navigate('/historical-alert');
    }

    // console.log('searching', searching);
    // console.log('ref', searchRef.current);


    if (!(permissions.view || webAdmin)) {
        return <Card
            sx={{
                borderRadius: '16px',
                position: 'relative',
                width: '100%',
                maxWidth: '700px',
                minHeight: '500px',
                height: '100%',
                maxHeight: '80vh',
                overflow: 'hidden',
                padding: 2,
                paddingBottom: 0
            }}>
            <CardHeader
                title="Active Alerts"
                subheader='Only showing the most recent 100 active alerts'
                subheaderTypographyProps={{variant: 'overline'}}
            />
            <CardContent>
                <IconButton disableRipple>
                    <FormatListBulletedIcon sx={{fontSize: 50}}/>
                </IconButton>
                <Typography variant="caption" color="grey">
                    No Access to Alerts
                </Typography>
            </CardContent>
        </Card>
    }

    return <>
        <Card
            sx={{
                borderRadius: '16px',
                position: 'relative',
                width: '100%',
                maxWidth: '700px',
                height: '80vh',
                maxHeight: '80vh',
                overflow: 'hidden',
                padding: 2,
                paddingBottom: 0
            }}>
            <Backdrop
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    color: '#ccc',
                }}
                open={patchLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <CardHeader
                title='Active Alerts'
                subheader='Only showing the most recent 100 active alerts'
                subheaderTypographyProps={{variant: 'overline'}}
                action={(permissions.view || webAdmin) ?
                    <>
                        <Button onClick={handleNavigate} >
                            View Dismissed Alerts
                        </Button>
                    </> :
                    null
                }
                key='alerts-header'
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        component="label"
                        onClick={openSearch ? handleCancelSearch : handleOpenSearch}
                        startIcon={<ManageSearchIcon/>}
                        sx={{mr: 2}}
                    >
                        {openSearch ? "Cancel" : "Filters"}
                    </Button>
                    <Button
                        onClick={handleSelectAll}
                        startIcon={<LibraryAddCheckOutlinedIcon/>}
                    >
                        {allSelected ? 'Deselect All' : 'Select All'}
                    </Button>
                </Box>
                <Button onClick={handleBatchDismiss} disabled={batchDismissAlert.length === 0}>
                    Dismiss Selected Alerts
                </Button>
            </Box>
            {openSearch && <Box sx={{width: '100%'}}>
                <SearchBox
                    prefix='u'
                    searchParams={searchParams}
                    setSearchParams={handleSearchParams}
                    searchFields={alertSearchFields}
                    sendSearch={handleSearch}
                    sx={{margin: 'auto', mt: 1, mb: 1, maxWidth: '100%'}}
                />
            </Box>}
            <CardContent
                key='alerts-content'
                sx={{
                    height: openSearch ? 'calc(100% - 327px)' : 'calc(100% - 150px)',
                    borderRadius: 2,
                    flexGrow: 1,
                    overflowY: 'auto',
                    mb: 2
                }}
            >
                {(searching && filteredAlerts && filteredAlerts.length !== 0) || (!searching && alerts && alerts.length !== 0) ?
                    <List sx={{width: '100%'}}>
                        {(searching ? filteredAlerts : alerts).map((alert) => (
                            <ListItem sx={{width: '100%', flexGrow: 1}} key={`listitem-${alert.id}`}>
                                <Collapse
                                    in={!closedAlerts.includes(alert.id)}
                                    sx={{width: '100%', flexGrow: 1,}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            checked={batchDismissAlert.includes(alert.id) || false}
                                            onClick={(e) => handleDismissCheck(e, alert.id)}
                                            name={String(alert.id)}
                                            sx={{mr: 2}}/>
                                        <Alert
                                            severity={severityToSeverity(alert.al_severity) === 'error' ? 'realtimeAlert' : severityToSeverity(alert.al_severity)}
                                            action={(permissions.edit || webAdmin) ?
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        dismissSingleAlert(alert.id);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit"/>
                                                </IconButton>
                                                : null
                                            }
                                            sx={{width: '100%', flexGrow: 1, borderRadius: 2}}
                                        >
                                            <AlertTitle
                                                sx={{
                                                    fontWeight: (alert.al_severity === 'Critical' ? 'bold' : 'normal'),
                                                    fontSize: (alert.al_severity === 'Critical' ? 19 : 17)
                                                }}
                                            >
                                                {alert.datetime} {alert.al_severity} Alert
                                            </AlertTitle>
                                            {alert.related_unit_id && <Typography variant='body2' key='unit-link'>
                                                Unit <Link component={RouterLink} underline="always" color="inherit"
                                                           to={`unit/${alert.related_unit_id}`}
                                                           state={{from: location}}>{alert.related_unit}</Link>
                                            </Typography>}
                                            {alert.related_camera_id && <Typography variant='body2' key='camera-link'>
                                                Camera <Link component={RouterLink} underline="always" color="inherit"
                                                             to={`camera/${alert.related_camera_id}`}
                                                             state={{from: location}}>{alert.related_camera}</Link>
                                            </Typography>}
                                            <Typography variant='body1' key='description'>
                                                {alert.al_description}
                                            </Typography>
                                        </Alert>
                                    </Box>
                                </Collapse>
                            </ListItem>
                        ))
                        }
                    </List> :
                    <>
                        <IconButton disableRipple>
                            <FormatListBulletedIcon sx={{fontSize: 50}}/>
                        </IconButton>
                        <Typography variant="caption" color="grey">
                            No Active Alerts
                        </Typography>
                    </>
                }
            </CardContent>
        </Card>
        <AlertConfirmation
            open={openDialog}
            handleClose={handleClose}
            setNotesOpen={setNotesOpen}
        />
        <AddNotes
            open={notesOpen}
            handleClose={() => setNotesOpen(false)}
            handleCancel={handleCancel}
            handleConfirm={handleConfirmDismiss}
            content={notesContent}
            setContent={setNotesContent}
        />
        <AddNotes
            open={singleNotesOpen}
            handleClose={() => setSingleNotesOpen(false)}
            handleCancel={handleSingleCancel}
            handleConfirm={handleConfirmDismissSingle}
            content={notesContent}
            setContent={setNotesContent}
        />
    </>
}